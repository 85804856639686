import {
  saveCayroStoreBanners,
  saveComboDeals,
  saveCombos,
  saveDeals,
  saveMetaInformation,
} from "../slice/CayroStoreSlice";
import { AppDispatch } from "../store";
import { showErrorToast } from "../../utils/toast";
import { GetRequest } from "../../api/Request";

export const asyncGetCombos = () => async (dispatch: AppDispatch) => {
  try {
    const response = await GetRequest("/deals/getAllComboName");
    await dispatch(saveCombos(response.data));
  } catch (error: any) {
    showErrorToast(error.response.data.message);
  }
};

export const asyncGetBanners = () => async (dispatch: AppDispatch) => {
  try {
    const response = await GetRequest("/deals/getAllBanners");
    await dispatch(saveCayroStoreBanners(response.data));
  } catch (error: any) {
    showErrorToast(error.response.data.message);
  }
};

export const asyncGetDealsByComboName =
  (comboId: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await GetRequest(
        `/deals/getDealsByComboName/${comboId}`
      );
      if (response.status === 200) {
        await dispatch(saveComboDeals(response.data.data));

        await dispatch(saveMetaInformation(response.data.metaInfo.metaInfo));
      }
    } catch (error: any) {
      showErrorToast(error.response?.data?.message || "Something went wrong");
    }
  };

export const asyncGetDealsByDealID =
  (dealId: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await GetRequest(`deals/getDealById/${dealId}`);
      if (response.status === 200) {
        await dispatch(saveDeals(response.data.data));
      }
    } catch (error: any) {
      showErrorToast(error.response.data.message);
    }
  };
