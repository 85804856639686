import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

interface CategoryType {
  _id: string;
  name: string;
  slug?: string;
  children: CategoryType[];
}

const Category = ({
  setShowCategory,
  isLoadingForCategory,
}: {
  setShowCategory: (value: boolean) => void;
  isLoadingForCategory: boolean;
}) => {
  const { catAndSubCat } = useSelector((state: any) => state.product);
  const categoryRef = useRef<HTMLDivElement>(null);

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0);
  const [selectedSubCategoryIndex, setSelectedSubCategoryIndex] =
    useState<number>(-1);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      categoryRef.current &&
      !categoryRef.current.contains(event.target as Node)
    ) {
      setShowCategory(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Helper function to build the full category path
  const getCategoryPath = (
    categoryIndex: number,
    subCategoryIndex?: number,
    subSubCategory?: CategoryType
  ): string => {
    const basePath = "category";
    const mainCategory = catAndSubCat[categoryIndex];
    let path = `${basePath}/${mainCategory.slug || mainCategory.name}`;

    if (subCategoryIndex !== undefined && subCategoryIndex >= 0) {
      const subCategory = mainCategory.children[subCategoryIndex];
      path += `/${subCategory.slug || subCategory.name}`;
    }

    if (subSubCategory) {
      path += `/${subSubCategory.slug || subSubCategory.name}`;
    }

    return path;
  };

  return (
    <section
      ref={categoryRef}
      className="rounded-xl relative bg-white flex border border-gray-200 shadow-xl max-w-[750px] overflow-hidden"
    >
      {/* Main Categories List */}
      <div className="w-[250px] border-r border-gray-200 overflow-y-auto max-h-[500px]">
        <div className="p-4 text-black flex items-center gap-2 sticky top-0 z-10 bg-white">
          <span className="w-6 h-6 flex items-center justify-center">
            <img
              src="https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741072981929_1741072981919.webp"
              className="w-5 h-5"
              alt="category icon"
            />
          </span>
          <span className="text-sm font-semibold">SHOP BY CATEGORIES</span>
        </div>
        {isLoadingForCategory ? (
          <div className="p-4 text-gray-500">Loading categories...</div>
        ) : (
          catAndSubCat.length > 0 &&
          catAndSubCat.map((category: CategoryType, index: number) => (
            <div
              key={category._id}
              className={`px-4 py-3 cursor-pointer flex justify-between items-center transition-all duration-200 border-b border-gray-200
                ${
                  selectedCategoryIndex === index
                    ? "bg-purple-100 text-purple-700 font-medium"
                    : "hover:bg-gray-100 text-gray-700"
                }`}
              onMouseEnter={() => {
                setSelectedCategoryIndex(index);
                setSelectedSubCategoryIndex(-1);
              }}
            >
              <Link
                to={getCategoryPath(index)}
                target="_blank"
                className="text-sm w-full"
                onClick={() => setShowCategory(false)}
              >
                {category.name}
              </Link>
              {category.children.length > 0 && (
                <RiArrowRightSLine className="text-xl text-gray-500" />
              )}
            </div>
          ))
        )}
      </div>

      {/* Subcategories Level 1 */}
      {selectedCategoryIndex >= 0 &&
        catAndSubCat[selectedCategoryIndex]?.children.length > 0 && (
          <div className="w-[250px] bg-white border-r border-gray-200 overflow-y-auto max-h-[500px]">
            <div className="p-4 bg-gray-50 text-gray-700 font-semibold text-sm sticky top-0 z-10">
              {catAndSubCat[selectedCategoryIndex].name}
            </div>
            {catAndSubCat[selectedCategoryIndex].children.map(
              (subCategory: CategoryType, index: number) => (
                <div
                  key={subCategory._id}
                  className={`px-4 py-3 cursor-pointer flex justify-between items-center transition-all duration-200 border-b border-gray-200
                ${
                  selectedSubCategoryIndex === index
                    ? "bg-purple-50 text-purple-600"
                    : "hover:bg-gray-50 text-gray-700"
                }`}
                  onMouseEnter={() => setSelectedSubCategoryIndex(index)}
                >
                  <Link
                    to={getCategoryPath(selectedCategoryIndex, index)}
                    target="_blank"
                    className="text-sm w-full"
                    onClick={() => setShowCategory(false)}
                  >
                    {subCategory.name}
                  </Link>
                  {subCategory.children.length > 0 && (
                    <RiArrowRightSLine className="text-xl text-gray-500" />
                  )}
                </div>
              )
            )}
          </div>
        )}

      {/* Subcategories Level 2 */}
      {selectedCategoryIndex >= 0 &&
        selectedSubCategoryIndex >= 0 &&
        catAndSubCat[selectedCategoryIndex]?.children[selectedSubCategoryIndex]
          ?.children.length > 0 && (
          <div className="w-[250px] bg-white overflow-y-auto max-h-[500px]">
            <div className="p-4 bg-gray-50 text-gray-700 font-semibold text-sm sticky top-0 z-10">
              {
                catAndSubCat[selectedCategoryIndex].children[
                  selectedSubCategoryIndex
                ].name
              }
            </div>
            {catAndSubCat[selectedCategoryIndex].children[
              selectedSubCategoryIndex
            ].children.map((subSubCategory: CategoryType) => (
              <div
                key={subSubCategory._id}
                className="px-4 py-3 cursor-pointer hover:bg-gray-50 text-gray-700 transition-all duration-200 border-b border-gray-200"
              >
                <Link
                  to={getCategoryPath(
                    selectedCategoryIndex,
                    selectedSubCategoryIndex,
                    subSubCategory
                  )}
                  target="_blank"
                  className="text-sm w-full"
                  onClick={() => setShowCategory(false)}
                >
                  {subSubCategory.name}
                </Link>
              </div>
            ))}
          </div>
        )}
    </section>
  );
};

export default Category;
