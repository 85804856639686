import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useSelector } from "react-redux";

interface Category {
  _id: string;
  name: string;
  slug: string;
  url: string;
  children: Category[];
}

const Sidebar = ({
  setShowSideBarInMobile,
}: {
  setShowSideBarInMobile: (value: boolean) => void;
}) => {
  const showSideBarInMobileRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<
    number | null
  >(null);
  const [selectedSubCategoryIndex, setSelectedSubCategoryIndex] = useState<
    number | null
  >(null);
  const { catAndSubCat } = useSelector((state: any) => state.product);

  // Handle click outside to close sidebar
  const handleClickOutside = (event: MouseEvent) => {
    if (
      showSideBarInMobileRef.current &&
      !showSideBarInMobileRef.current.contains(event.target as Node)
    ) {
      setShowSideBarInMobile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Helper function to build the full category path
  const getCategoryPath = (
    categoryIndex: number,
    subCategoryIndex?: number,
    subSubCategory?: Category
  ): string => {
    const basePath = "category";
    const mainCategory = catAndSubCat[categoryIndex];
    let path = `${basePath}/${mainCategory.slug || mainCategory.name}`;

    if (subCategoryIndex !== undefined && subCategoryIndex !== null) {
      const subCategory = mainCategory.children[subCategoryIndex];
      path += `/${subCategory.slug || subCategory.name}`;
    }

    if (subSubCategory) {
      path += `/${subSubCategory.slug || subSubCategory.name}`;
    }

    return path;
  };

  // Navigate to category page with full path
  const handleNavigate = (
    categoryIndex: number,
    subCategoryIndex?: number,
    subSubCategory?: Category
  ) => {
    const fullPath = getCategoryPath(
      categoryIndex,
      subCategoryIndex,
      subSubCategory
    );
    setShowSideBarInMobile(false);
    navigate(fullPath);
  };

  // Toggle category expansion
  const handleCategoryToggle = (index: number) => {
    setSelectedCategoryIndex(selectedCategoryIndex === index ? null : index);
    setSelectedSubCategoryIndex(null); // Reset subcategory when toggling top-level
  };

  // Toggle subcategory expansion
  const handleSubCategoryToggle = (subIndex: number) => {
    setSelectedSubCategoryIndex(
      selectedSubCategoryIndex === subIndex ? null : subIndex
    );
  };

  if (!catAndSubCat || catAndSubCat.length === 0) {
    return <div className="p-4 text-gray-500">No categories available</div>;
  }

  return (
    <div
      ref={showSideBarInMobileRef}
      className="fixed w-full h-screen top-0 left-0 z-50 bg-white p-4 overflow-y-auto max-w-[280px] transition-transform duration-300"
      style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
    >
      {/* Close Button */}
      <button
        onClick={() => setShowSideBarInMobile(false)}
        className="absolute top-3 right-3 text-gray-500 hover:text-gray-900 p-1 rounded-full hover:bg-gray-100"
      >
        <svg
          className="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <span className="sr-only">Close menu</span>
      </button>

      {/* Header */}
      <h2 className="text-lg font-semibold text-gray-800 mb-4">
        All Categories
      </h2>
      <hr className="mb-4 border-gray-200" />

      {/* Category List */}
      <ul className="space-y-2">
        {catAndSubCat.map((category: Category, index: number) => (
          <li key={category._id}>
            <div className="flex items-center justify-between p-3 rounded-lg cursor-pointer text-gray-700 hover:bg-gray-100 transition-colors duration-200">
              <span
                className="text-sm font-medium flex-1"
                onClick={() => handleNavigate(index)}
              >
                {category.name}
              </span>
              {category.children.length > 0 && (
                <MdKeyboardArrowDown
                  className={`text-xl transition-transform duration-200 ${
                    selectedCategoryIndex === index ? "rotate-180" : ""
                  }`}
                  onClick={() => handleCategoryToggle(index)}
                />
              )}
            </div>

            {/* Subcategories (Level 1) */}
            {selectedCategoryIndex === index &&
              category.children.length > 0 && (
                <ul className="ml-4 mt-2 space-y-2">
                  {category.children.map(
                    (subcat: Category, subIndex: number) => (
                      <li key={subcat._id}>
                        <div className="flex items-center justify-between p-2 rounded-lg cursor-pointer text-gray-600 hover:bg-gray-50 transition-colors duration-200">
                          <span
                            className="text-sm flex-1"
                            onClick={() => handleNavigate(index, subIndex)}
                          >
                            {subcat.name}
                          </span>
                          {subcat.children.length > 0 && (
                            <MdKeyboardArrowDown
                              className={`text-lg transition-transform duration-200 ${
                                selectedSubCategoryIndex === subIndex
                                  ? "rotate-180"
                                  : ""
                              }`}
                              onClick={() => handleSubCategoryToggle(subIndex)}
                            />
                          )}
                        </div>

                        {/* Sub-subcategories (Level 2) */}
                        {selectedSubCategoryIndex === subIndex &&
                          subcat.children.length > 0 && (
                            <ul className="ml-4 mt-2 space-y-1">
                              {subcat.children.map((subSubCat: Category) => (
                                <li key={subSubCat._id}>
                                  <span
                                    className="block p-2 text-sm text-gray-600 hover:text-purple-500 hover:bg-gray-50 rounded-lg transition-colors duration-200 cursor-pointer"
                                    onClick={() =>
                                      handleNavigate(index, subIndex, subSubCat)
                                    }
                                  >
                                    {subSubCat.name}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}
                      </li>
                    )
                  )}
                </ul>
              )}
          </li>
        ))}
      </ul>

      {/* Cayrostore Link */}
      <hr className="my-4 border-gray-200" />
      <Link
        to="/cayrostore"
        className="block text-sm font-medium text-gray-700 hover:text-purple-600 p-3 rounded-lg hover:bg-gray-100"
        onClick={() => setShowSideBarInMobile(false)}
      >
        Cayrostore
      </Link>
    </div>
  );
};

export default Sidebar;
