import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: any = {
  products: [],
  totalMRP: 0,
  subtotal: 0,
  discount: 0,
  totalTax: 0,
  flatTenPercentOff: 0,
  shippingCharges: 150,
  total: 0,
  coupons: null,
  appliedCoupon: null, // New State for Applied Coupon
  couponSavings: JSON.parse(
    localStorage.getItem("couponSavings") || '{"code": null, "discount": 0}'
  ), // Load coupon from localStorage
  length: JSON.parse(localStorage.getItem("cartLength") || "0"), // Set length from localStorage cart or 0
};

const updateSubtotal = (state: any) => {
  let subtotal = 0;
  let totalTax = 0;
  let totalMRP = 0;
  let totalSalePrice = 0;
  let totalDiscount = 0;

  state.products.forEach((product: any, index: number) => {
    const basePrice = Number(product.total) || 0; // Base price of the product
    const mrpPrice = product.regularPrice ? Number(product.regularPrice) : 0; // MRP Price
    const salePrice = product.salePrice ? Number(product.salePrice) : 0; // Sale Price
    const quantity = product.quantityByUser || 1;
    const gstRate = product.gst || 0;
    const gstAmount = (basePrice * gstRate) / (100 + gstRate) || 0;
    subtotal += basePrice;
    totalTax += gstAmount * quantity;
    totalMRP += mrpPrice * quantity; // Multiply MRP by quantity
    totalSalePrice += salePrice * quantity;
  });

  totalDiscount = totalMRP - totalSalePrice;

  state.subtotal = subtotal || 0;
  state.totalTax = totalTax || 0;
  state.totalMRP = totalMRP || 0;
  state.totalSalePrice = totalSalePrice || 0;
  state.discount = totalDiscount || 0;
};

const updateTotal = (state: any) => {
  const subtotal = state.totalSalePrice || 0;
  const couponDiscount = state.couponSavings.discount || 0;
  const shippingCharges = state.shippingCharges || 0;

  // Apply the coupon discount
  let total = subtotal - couponDiscount;

  // Ensure the total is never negative
  if (total < 0) {
    total = 0;
  }

  state.total = total;
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartLength: (state, action: PayloadAction<any>) => {
      state.length = action.payload;

      localStorage.setItem("cartLength", JSON.stringify(action.payload));
    },

    addProduct: (state, action: PayloadAction<any>) => {
      const existingProduct = state.products.find(
        (product: any) => product._id === action.payload._id
      );

      if (existingProduct) {
        existingProduct.quantityByUser += action.payload.quantityByUser;
        existingProduct.total =
          existingProduct.quantityByUser * Number(existingProduct.salePrice);
      } else {
        state.products.push({
          ...action.payload,
          total: action.payload.quantityByUser * action.payload.salePrice,
        });
      }

      updateSubtotal(state);

      state.length = state.products.length;

      localStorage.setItem("cart", JSON.stringify(state.products));
      localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
    },

    removeProduct: (state, action: PayloadAction<string>) => {
      const index = state.products.findIndex(
        (product: any) => product._id === action.payload
      );

      if (index !== -1) {
        state.products.splice(index, 1); // Remove the product

        // Update subtotal and calculate totals
        updateSubtotal(state);
        updateTotal(state);
        // Update local storage
        localStorage.setItem("cart", JSON.stringify(state.products));
        localStorage.setItem("subtotal", JSON.stringify(state.subtotal));
      }
    },

    clearFromLocalStorage: (state) => {
      state.products = [];
      state.subtotal = 0;
      state.flatTenPercentOff = 0; // Reset flat discount when clearing cart

      // Calculate totals after clearing cart
      localStorage.removeItem("cart");
      clearCouponSavings(state);
    },

    saveCart: (state, action) => {
      state.loading = true;
      state.products = action.payload.products;
      state.length = action.payload.length;
      updateSubtotal(state);
      updateTotal(state);

      state.loading = false;
    },

    setProductInCart: (state, action) => {
      if (action.payload.product.quantityByUser < 1) {
        state.products = state.products.filter(
          (_product: any, indexOfProduct: number) =>
            indexOfProduct !== action.payload.index
        );
      } else {
        state.products[action.payload.index] = action.payload.product;
      }
      // Update subtotal and calculate totals
      updateSubtotal(state);
      updateTotal(state);
    },

    saveAllCoupons: (state, action) => {
      state.coupons = action.payload;
    },

    applyCoupon: (state, action: PayloadAction<any>) => {
      state.products = action.payload.products;
      state.appliedCoupon = action.payload.coupon.code;

      // Apply coupon discount if present
      if (action.payload.coupon && action.payload.coupon.discount) {
        state.couponSavings = {
          code: action.payload.coupon.code,
          discount: action.payload.coupon.discount,
        };
      } else {
        state.couponSavings = {
          code: null,
          discount: 0,
        };
      }

      localStorage.setItem(
        "couponSavings",
        JSON.stringify(state.couponSavings)
      );

      updateSubtotal(state);
    },

    clearCouponSavings: (state) => {
      localStorage.removeItem("couponSavings");

      state.couponSavings = {
        code: null,
        discount: 0,
      };

      state.appliedCoupon = null;

      updateSubtotal(state);
      updateTotal(state);
    },

    applyCouponWhenLost: (state, action) => {
      if (action.payload.couponCode && action.payload.discountOffer) {
        // Jab coupon code & discount dono ho
        state.couponSavings = {
          code: action.payload.couponCode,
          discount: action.payload.discountOffer,
        };
      } else if (action.payload.couponCode) {
        // Jab sirf coupon code ho, discount na ho
        state.couponSavings = {
          code: action.payload.couponCode,
        };
      } else {
        // Jab coupon apply hi nahi kiya gaya
        state.couponSavings = {
          code: null,
          discount: 0,
        };
      }

      localStorage.setItem(
        "couponSavings",
        JSON.stringify(state.couponSavings)
      );

      updateSubtotal(state);
      updateTotal(state);
    },
  },
});

// Export actions and reducer
export const {
  addProduct,
  removeProduct,
  setCartLength,
  setProductInCart,
  saveCart,
  saveAllCoupons,
  clearFromLocalStorage,
  applyCoupon,
  clearCouponSavings,
  applyCouponWhenLost,
} = cartSlice.actions;

export default cartSlice.reducer;

export const totalCartItem = (state: RootState) => {
  return state.cart.length;
};

export const allProduct = (state: RootState) => {
  return state.cart.products;
};
