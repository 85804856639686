import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer
      // style={{ backgroundColor: "rgba(204, 212, 255, 0.6)" }}
      className="text-black flex flex-col justify-center items-center w-[95%] mx-auto my-4"
    >
      <div className="grid grid-cols-5 md:grid-cols-3 gap-10 xs:grid-cols-2 sm:grid-cols-2">
        {/* logo address */}
        <div className=" space-y-10">
          <div>
            <img
              src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1740223736636_1740223736633.webp"
              alt=""
              className="mb-2 w-[100px]"
            />
            <p className="text-sm sm:text-xs text-justify">
              Showroom @ Kherki Daula, Near Toll Plaza, Distt Gurugram, Haryana
              122004
            </p>
          </div>
          <ul className="flex  justify-start gap-4  sm:hidden">
            <li className="mb-3 hover:underline text-sm sm:text-xs">
              <a
                href="https://www.instagram.com/cayro.shop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="text-pink-500 text-3xl" />
              </a>
            </li>
            <li className="mb-3 hover:underline">
              <a
                href="https://www.facebook.com/profile.php?id=61558680970395"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="text-blue-600 text-3xl" />
              </a>
            </li>
            <li className="mb-3 hover:underline">
              <a
                href="https://www.youtube.com/@CayroShop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube className="text-red-600 text-3xl" />
              </a>
            </li>
            <li className="mb-3 hover:underline">
              <a
                href="https://x.com/cayro_shop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter className="text-black text-3xl" />
              </a>
            </li>
            <li className="mb-3 hover:underline">
              <a
                href="https://in.pinterest.com/shopcayro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaPinterest className="text-red-500 text-3xl" />
              </a>
            </li>
          </ul>
        </div>
        {/* shopping */}
        <div className="ml-[100px] sm:ml-0">
          <h2 className="text-s sm:text-sm font-bold mb-4">Shopping</h2>
          <ul>
            <li className="mb-3 hover:underline text-sm sm:text-xs">
              <Link to="/wishlist">Wishlist</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/">Shop by Brand</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/blogs">Blogs</Link>
            </li>
          </ul>
        </div>

        <div className="ml-[100px] sm:ml-0">
          <h2 className="text-s sm:text-sm font-bold mb-4">Get to Know Us</h2>
          <ul>
            <li className="mb-3 hover:underline text-sm sm:text-xs">
              <Link to="/about">About Us</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/privacy-policy">Privacy Policy </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/contact">Contact Us </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/faqs">FAQs </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/sitemap">Sitemap </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="http://seller.cayroshop.com">Sell Your Product </Link>
            </li>

            {/* <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/">Blogs </Link>
            </li> */}
          </ul>
        </div>

        {/* order & Return */}
        <div className="ml-[100px] sm:ml-0">
          <h2 className="text-s sm:text-sm font-bold mb-4">Order & Return</h2>
          <ul>
            <li className="mb-3 hover:underline text-sm sm:text-xs">
              <Link to="/return-and-refund">Return & Refund</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/shipping-and-delivery">Shipping & Delivery </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/terms-and-condition">Term & conditions </Link>
            </li>
          </ul>
        </div>

        {/* products  */}
        <div className="ml-[100px] sm:ml-0">
          <h2 className="text-s sm:text-sm font-bold mb-4">Products</h2>
          <ul>
            <li className="mb-3 hover:underline text-sm sm:text-xs">
              <Link to="/category/home-appliances">Home Appliances</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/category/television">Television </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/category/kitchen-appliances">Kitchen Appliances </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/category/mobiles">Mobiles </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/category/self-care">Self Care </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/category/accessories">Accessories </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs ">
              <Link to="/category/audio-&-video">Audio & Video </Link>
            </li>
          </ul>
        </div>
        {/* Get to Know Us */}
      </div>
      <div className="flex items-start sm:flex-col text-gray-600 w-full">
        <ul className="gap-6  hidden sm:flex">
          <li className="mb-3 hover:underline text-sm sm:text-xs">
            <a
              href="https://www.instagram.com/cayro.shop"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-pink-500 text-3xl" />
            </a>
          </li>
          <li className="mb-3 hover:underline">
            <a
              href="https://www.facebook.com/profile.php?id=61558680970395"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="text-blue-600 text-3xl" />
            </a>
          </li>
          <li className="mb-3 hover:underline">
            <a
              href="https://www.youtube.com/@CayroShop"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube className="text-red-600 text-3xl" />
            </a>
          </li>
          <li className="mb-3 hover:underline">
            <a
              href="https://x.com/cayro_shop"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter className="text-black text-3xl" />
            </a>
          </li>
          <li className="mb-3 hover:underline">
            <a
              href="https://in.pinterest.com/shopcayro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaPinterest className="text-red-500 text-3xl" />
            </a>
          </li>
        </ul>
        <div className="text-sm text-center mx-auto mt-2 sm:text-xs ">
          <p>
            &copy; 2025 &nbsp;
            <a
              href="https://www.cayroshop.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700"
            >
              <span className="text-blue">cayroshop.com</span>
            </a>
            &nbsp; All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
