import { Link } from "react-router-dom";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { useCallback, useEffect, useRef, useState } from "react";
import ProductForSale from "./ProductForSale";
import Brand from "./Brand";
import { GetRequest } from "../../api/Request";
import Loader from "../../common/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import HeroSection from "./HeroSection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery } from "@react-hook/media-query";
import Banners from "./Banners";
import Products from "./Products";
import Banner2 from "./Banner2";
import BestSellingProducts from "./BestSellingProducts";
import TopCellPhones from "./TopCellPhones";
import HomeAppliances from "./HomeAppliances";
import BannerCarosuel from "./BannerCarosuel";
import StyleSection from "./TopStories";
import { Helmet } from "react-helmet";
import {
  asyncGetAllLatestProducts,
  asyncGetRecommendedProducts,
  asyncGetRootCategory,
} from "../../store/actions/productAction";
import { AppDispatch, RootState } from "../../store/store";
import ShopByProduct from "./ShopByProduct";

const HomePage = () => {
  const { categories, latestProducts, recommendedProducts } = useSelector(
    (state: RootState) => state.product
  );
  const containerRef = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useMediaQuery("(max-width: 500px)");
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [allBanner, setAllBanner] = useState<any>([]);
  const [isLoadingForBanner, setIsLoadingForBanner] = useState<Boolean>(false);
  const [isFading, setIsFading] = useState(false);
  const userIsLoggedIn = useSelector(
    (state: RootState) => state.user.isLoggedIn
  );

  const prevSlide = () => {
    setIsFading(true);
    setTimeout(() => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? allBanner.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this duration with your CSS transition duration
  };

  const nextSlide = () => {
    setIsFading(true);
    setTimeout(() => {
      const isLastSlide = currentIndex === allBanner.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
      setIsFading(false);
    }, 500); // Match this duration with your CSS transition duration
  };

  const getAllBanner = useCallback(async () => {
    let url = "/getBannerAccToDevice?display_type=";
    url += isMobile ? "Mobile" : "Desktop";
    setIsLoadingForBanner(true);
    try {
      const response = await GetRequest(url);
      if (response.status === 200) {
        setAllBanner(response?.data);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
    setIsLoadingForBanner(false);
  }, [isMobile]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(asyncGetAllLatestProducts());
      await Promise.all([getAllBanner()]);
    };
    fetchData();
  }, [getAllBanner]);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      dispatch(asyncGetRecommendedProducts());
    }
  }, [localStorage.getItem("userToken")]); // Include `getAllRecommendedProducts` as a dependency

  useEffect(() => {
    const autoSlideInterval = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(autoSlideInterval);
  }, [currentIndex, allBanner.length]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(asyncGetRootCategory());
  }, [dispatch]);
  const keywords = [
    "Online Shopping in Gurugram",
    "online Shopping store",
    "Online Shopping Site",
    "Buy Online",
    "Shop Online",
    "Online Shopping",
    "Cayroshop",
    "Home Appliances",
    "Electronics Online",
    "Smart TVs",
    "Washing Machines",
    "Refrigerators",
    "Air Conditioners",
    "Microwave Ovens",
    "Kitchen Appliances",
    "Air Purifiers",
    "Dishwashers",
    "Affordable Electronics",
    "Online Appliance Store",
    "Best Electronics Deals",
    "Buy Electronics Online",
    "Latest Gadgets",
    "Top Electronics Brands",
    "Discount Home Appliances",
    "Quality Electronics",
    "LED Televisions",
    "Home Entertainment Systems",
    "Smart TVs",
    "LG OLED TVs",
    "Sony Bravia TVs",
    "4K TVs",
    "Xiaomi Mi LED TVs",
    "Panasonic Smart TVs",
    "QLED TVs",
    "Ultra HD TVs",
    "Smart TVs",
    "Android TVs",
  ];

  return (
    <div className="relative">
      <Helmet>
        <title>Cayroshop | Best Electronics Store in Gurugram</title>
        <meta
          name="description"
          content="Shop top electronics, smart gadgets & home appliances at Cayroshop. Enjoy unbeatable prices, exclusive discounts & fast delivery in Gurugram!"
        />
        <meta name="keywords" content={keywords.join(", ")} />
        <link rel="canonical" href="https://www.cayroshop.com/" />
      </Helmet>
      <div className="overfow-hidden">
        <div
          ref={containerRef}
          className="w-full overflow-y-hidden  overflow-x-auto mt-[6rem]   pl-[10px]  sm:pl-2 whitespace-nowrap no-scrollbar flex justify-center sm:justify-start lg:justify-start xl:justify-start md:justify-start "
        >
          {categories?.length > 0 &&
            categories.map((item: any, index: number) => {
              return (
                <div
                  className="category sm:!mr-[1rem] flex-shrink-0"
                  key={item._id}
                >
                  {/* It's a good practice to include a unique key for the list item */}
                  <Link
                    to={{
                      pathname: `category/${item.slug}`,
                    }}
                    className="flex justify-center items-center flex-col"
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={item?.image?.url}
                      className="category-img"
                      alt={item?.name}
                    />
                    <h2 className="text-[13px] font-[450] sm:max-w-[100px] mt-[0.3rem] sm:text-[11px] text-center">
                      {item?.name}
                    </h2>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>

      <div className="flex items-center mb-[3.5rem]  z-0 sm:top-[83px]  xss:top-[5rem]  sm:my-[0.9rem] absolute">
        {isLoadingForBanner && allBanner?.length === 0 ? (
          <div className="flex justify-center items-center h-[200px] sm:h-[83px] sm:w-[83vw] w-[100vw] ">
            <Loader />
          </div>
        ) : (
          <div
            // className="w-[100vw] m-auto  cursor-pointer  sm:mt-6 mt-6 sm:m-0 m-auto relative overflow-hidden"
            className="cursor-pointer  relative overflow-hidden"
          >
            <Link
              to={allBanner[currentIndex]?.banner_link}
              className="cursor-pointer"
            >
              <LazyLoadImage
                effect="blur"
                src={allBanner?.length > 0 && allBanner[currentIndex]?.url}
                alt={"cayroshop | " + allBanner[currentIndex]?.name}
                className={`w-[100vw] h-[700px] mt-[20px] xss:mt-[20px] smm:mt-[20px] sm:mt-[45px] xs:mt-[20px]   sm:h-auto z-[-1] cursor-pointer object-cover object-top duration-500 transition-opacity ${
                  isFading ? "opacity-40" : "opacity-100"
                }`}
              />
            </Link>

            <div className="group-hover:block absolute top-[25%] sm:top-[35%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft
                onClick={allBanner.length > 0 ? prevSlide : () => {}}
                size={20}
              />
            </div>

            <div className="group-hover:block absolute top-[25%] sm:top-[35%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight
                onClick={allBanner.length > 0 ? nextSlide : () => {}}
                size={20}
              />
            </div>
          </div>
        )}
      </div>

      <div className="sm:mt-[12rem] mt-[20rem] z-[999]">
        <div className="relative flex flex-wrap justify-around  ">
          <HeroSection
            heading={"Appliances for your Home | Up to 22% Off"}
            info={[
              {
                name: "Air Conditioner",
                link: "/category/home-appliances/air-conditioner",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966018315_1739966018312.webp",
              },
              {
                name: "Ceiling Fan",
                link: "/category/home-appliances/fans/ceiling-fans",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966130204_1739966130201.webp",
              },
              {
                name: "Television",
                link: "/category/television",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966625493_1739966625488.webp",
              },

              {
                name: "Washing Machine",
                link: "/category/home-appliances/washing-machine",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966703075_1739966703071.webp",
              },
              ,
            ]}
          />
          <HeroSection
            heading={"Appliances for your Kitchen | Up to 33% Off"}
            info={[
              {
                name: "Refrigerator",
                link: "/category/home-appliances/refrigerators--freezers",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966510352_1739966510348.webp",
              },
              {
                name: "Microwave Oven",
                link: "/category/kitchen-appliances/microwave-ovens",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966439971_1739966439969.webp",
              },

              {
                name: "Nutri Blender",
                link: "/category/kitchen-appliances/nutri-blender",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966103150_1739966103101.webp",
              },
              {
                name: "Water Purifier",
                link: "/category/kitchen-appliances/water-purifiers--dispensers/water-purifiers",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966725851_1739966725850.webp",
              },
            ]}
          />
          <HeroSection
            heading={"Items which you can gift"}
            info={[
              {
                name: "Mobiles",
                link: "/category/mobiles/mobile-phones",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966462572_1739966462571.webp",
              },
              {
                name: "Headphones",
                link: "/category/audio-&-video/headphones-&-earphones",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966261529_1739966261525.webp",
              },
              {
                name: "Laptops",
                link: "/category/laptops",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966384604_1739966384602.webp",
              },
              {
                name: "Smart Watch",
                link: "/category/accessories/watch",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966601860_1739966601857.webp",
              },
            ]}
          />

          <HeroSection
            heading={"Appliances for the Comfort"}
            info={[
              {
                name: "Iron",
                link: "/category/self-care/irons",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966355876_1739966355874.png",
              },
              {
                name: "Slow Cooker",
                link: "/category/kitchen-appliances/slow-cooker",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966188424_1739966188421.png",
              },
              {
                name: "Hair Dryer",
                link: "/category/self-care/hair-care-products",
                imgUrl:
                  "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739966225029_1739966225027.png",
              },
              {
                name: "Air Purifier",
                link: "/category/home-appliances/air-purifiers",
                imgUrl:
                  "https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/product/67162504f730a37683d9acf2/1729572131691_1729572131646.webp",
              },
            ]}
          />
        </div>
        <HomeAppliances />
        <hr />

        <TopCellPhones />

        <ShopByProduct />

        <Banners />
        <BestSellingProducts />
        <Products />
        <Banner2 />
        <Brand />

        <hr />
        <BannerCarosuel />

        {userIsLoggedIn ? (
          recommendedProducts?.length > 0 ? (
            <ProductForSale
              products={recommendedProducts}
              heading={"Recommended Products"}
              relatedProductPage={false}
            />
          ) : (
            latestProducts?.length > 0 && (
              <ProductForSale
                products={latestProducts}
                heading={"Latest Products"}
                relatedProductPage={false}
              />
            )
          )
        ) : (
          latestProducts?.length > 0 && (
            <ProductForSale
              products={latestProducts}
              heading={"Latest Products"}
              relatedProductPage={false}
            />
          )
        )}

        <StyleSection />
      </div>
    </div>
  );
};

export default HomePage;
