import { useDispatch, useSelector } from "react-redux";
import {
  compareProducts,
  removeProductForComparison,
  setSubCategoryIdForComparison,
} from "../../store/slice/CompareProductsSlice";
import { RxCross1 } from "react-icons/rx";
import { FaPlus } from "react-icons/fa";
import { AppDispatch } from "../../store/store";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Link } from "react-router-dom";

const CompareItems = ({ isComparisonPage }: any) => {
  let compareTheseProducts = useSelector(compareProducts);
  let compareProductsLength = compareTheseProducts.length;

  const dispatch = useDispatch<AppDispatch>();
  const calculateDiscountPercentage = (salePrice: any, regularPrice: any) => {
    if (regularPrice <= salePrice) {
      throw new Error("Regular price must be greater than sale price.");
    }
    const discountPercentage =
      ((regularPrice - salePrice) / regularPrice) * 100;
    return discountPercentage.toFixed(2); // Returns percentage with two decimal places
  };

  const removeProduct = (productId: any) => {
    dispatch(removeProductForComparison(productId));
    dispatch(setSubCategoryIdForComparison(""));
  };

  return (
    <div className="flex space-x-4 overflow-x-auto flex-nowrap">
      {compareProductsLength > 0 &&
        compareTheseProducts.map((product: any, index: any) => (
          <div
            key={index}
            className={
              isComparisonPage
                ? "bg-white w-[200px] w-[100%] sm:w-[175px] max-h-[200px] relative flex justify-center items-center flex-col rounded-lg p-4 sm:p-2"
                : "bg-white w-[250px] sm:w-[175px] max-h-[287px] relative flex justify-center items-center flex-col rounded-lg p-4 sm:p-2"
            }
          >
            {!isComparisonPage && (
              <RxCross1
                onClick={() => removeProduct(product._id)}
                className="text-red-500 sm:text-[0.7rem] absolute top-4 sm:top-2 sm:right-2 right-4 cursor-pointer"
              />
            )}
            <Link
              to={`/product?name=${product.productName}&product_id=${product._id}`}
            >
              {" "}
              <LazyLoadImage
                effect="blur"
                src={product?.images?.length > 0 ? product.images[0].url : ""}
                alt={product?.productName}
                className={
                  isComparisonPage
                    ? "w-20 h-20 object-contain sm:h-[100px] sm:w-[100px] sm:mb-0 rounded-md mb-4"
                    : "w-40 h-40 object-contain sm:h-[100px] sm:w-[100px] sm:mb-0 rounded-md mb-4"
                }
              />
            </Link>

            <Link
              to={`/product?name=${product.productName}&product_id=${product._id}`}
            >
              <h3
                className={
                  isComparisonPage
                    ? "text-xs sm:w-[175px] sm:mt-[0.3rem] sm:text-[0.8rem] line-clamp-2 font-semibold mb-2 text-center"
                    : "text-xs sm:mt-[0.3rem] sm:text-[0.8rem] line-clamp-2 font-semibold mb-2 text-center"
                }
              >
                {product?.productName}
              </h3>
            </Link>
            <div>
              <div
                className={
                  isComparisonPage
                    ? "text-xs sm:text-[0.8rem] text-center"
                    : "sm:text-[0.8rem] text-center"
                }
              >
                ₹{product?.salePrice}
                <span className="text-gray-500 sm:text-[0.6rem] text-xs ml-[0.4rem] inline-block line-through">
                  ₹{product?.regularPrice}
                </span>
              </div>

              <span
                className={
                  isComparisonPage
                    ? " w-[100%] text-[#CD0B39]  text-[10px] relative flex justify-center items-center flex-col  rounded-lg"
                    : "sm:text-[13px] bg-white w-[250px] text-[#CD0B39] sm:w-[175px] max-h-[287px] relative flex justify-center items-center flex-col  rounded-lg  sm:p-0"
                }
              >
                {calculateDiscountPercentage(
                  Number(product?.salePrice),
                  Number(product?.regularPrice)
                ) + "% off"}
              </span>
            </div>
          </div>
        ))}

      {/* Show Plus Icons */}
      {[...Array(5 - compareProductsLength)].map((_, index) => (
        <div
          key={index}
          className="bg-white w-[250px] sm:w-[175px] max-h-[287px] relative flex justify-center items-center flex-col rounded-lg p-4 sm:p-2"
        >
          <FaPlus className="text-[#ddd] sm:text-[18px] text-[24px] cursor-pointer mx-1" />
        </div>
      ))}
    </div>
  );
};

export default CompareItems;
