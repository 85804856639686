import React, { useEffect, useRef } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { asyncGetChildCategories } from "../../store/actions/productAction";

const ShopByProduct = () => {
  const subCategoryRef = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { childCategory } = useSelector((state: RootState) => state.product);

  useEffect(() => {
    dispatch(asyncGetChildCategories());
  }, []);

  const scrollLeft = (ref: any) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = (ref: any) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: 400, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  // Function to truncate name to two words and add ellipsis
  const truncateName = (name: string) => {
    const words = name.split(" ");
    if (words.length > 2) {
      return `${words[0]} ${words[1]} ...`;
    }
    return name;
  };

  return (
    <div className="p-4  my-0 mx-auto z-10 relative top-[50%]">
      <h3 className="text-xl md:text-xl font-semibold  flex items-center justify-center gap-2">
        Shop By Products
        <hr className="flex-grow border-t-2 border-gray-300" />
      </h3>
      <button
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
        onClick={() => scrollRight(subCategoryRef)}
        className="absolute right-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10 opacity-[0.6]"
        aria-label="Scroll Right"
      >
        <MdOutlineKeyboardArrowRight />
      </button>
      <ul
        ref={subCategoryRef}
        className="categories-on-home  overflow-y-scroll no-scrollbar"
        style={{
          justifyContent: "flex-start",
          marginBottom: "1rem",
        }}
      >
        {childCategory.length > 0 &&
          childCategory.map((subCat: any, index: number) => {
            return (
              <li className="category flex justify-center ">
                <Link
                  to={`category/${subCat.routeSlug}`}
                  target="_blank"
                  className="flex justify-center items-center flex-col"
                >
                  <LazyLoadImage
                    effect="blur"
                    src={subCat?.url}
                    className="category-img"
                    alt="category"
                  />
                  <p className="text-[14px] whitespace-normal sm:max-w-[70px] font-[480] mt-[0.3rem] sm:text-[11px] text-center">
                    {/* Apply truncation only on full screen (larger than sm) */}
                    <span className="hidden sm:inline">{subCat?.name}</span>
                    <span className="inline sm:hidden">
                      {truncateName(subCat?.name)}
                    </span>
                  </p>
                </Link>
              </li>
            );
          })}
      </ul>
      <button
        onClick={() => scrollLeft(subCategoryRef)}
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
        className="absolute opacity-[0.6] left-[4px] sm:hidden text-[2rem] bg-white h-[70px] flex justify-center items-center w-[50px]  top-[50%] text-black transform translate-y-[-50%] z-10"
        aria-label="Scroll Right"
      >
        <MdOutlineKeyboardArrowLeft />
      </button>
    </div>
  );
};

export default ShopByProduct;
