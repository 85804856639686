import { useCallback, useState, useEffect } from "react";
import { GetRequest } from "../../api/Request";
import { useSelector } from "react-redux";
import { allProduct } from "../../store/slice/CartSlice";
import BadaDiscount from "./BadaDiscount";

const Discount = () => {
  const [latestProducts, setLatestProducts] = useState<any[]>([]);
  const [bgImage, setBgImage] = useState("/cayrostore/mela.svg"); // Default desktop image
  const cartProducts = useSelector(allProduct);

  const getAllLatestProducts = useCallback(async () => {
    try {
      const response = await GetRequest("getAllProducts?latest=true");
      if (response.status === 200) {
        setLatestProducts(() =>
          response?.data?.products?.docs.map((product: any) => {
            const cartProduct =
              cartProducts &&
              cartProducts?.find(
                (cartItem: any) => cartItem?._id === product?._id
              );
            return {
              ...product,
              quantityByUser: cartProduct ? cartProduct.quantityByUser : 1,
              isLoading: false,
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching latest products:", error);
    }
  }, [cartProducts]);

  useEffect(() => {
    getAllLatestProducts();

    // Change background image based on screen size
    const updateImage = () => {
      if (window.innerWidth < 1123) {
        setBgImage("/cayrostore/mobile_dhamaka.webp"); // Mobile version
      } else {
        setBgImage("/cayrostore/mela.svg"); // Desktop version
      }
    };

    updateImage(); // Set initial image
    window.addEventListener("resize", updateImage);
    return () => window.removeEventListener("resize", updateImage);
  }, [getAllLatestProducts]);

  return (
    <>
      <h1 className="uppercase text-center text-3xl">Bada discount mela</h1>
      <div
        className="relative z-111 bg-center bg-no-repeat bg-cover w-full h-[90vh] lg:bg-top lg:h-auto"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <BadaDiscount
          products={latestProducts}
          setProduct={setLatestProducts}
          relatedProductPage={false}
        />
      </div>
    </>
  );
};

export default Discount;
