import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductForSale from "../HomePage/ProductForSale";
import { useMediaQuery } from "@react-hook/media-query";
import { AppDispatch, RootState } from "../../store/store";
import { asyncGetAllLatestProducts } from "../../store/actions/productAction";

const Gifting = () => {
  const { latestProducts } = useSelector((state: RootState) => state.product);
  const isMobile = useMediaQuery("(max-width: 767px)");
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(asyncGetAllLatestProducts());
  }, [dispatch]);

  return (
    <>
      <div className="flex l w-full h-auto md:flex-col ">
        <div className="flex flex-col w-[30%] md:w-full relative items-center justify-center md:bg-gradient-to-b from-amber-50 to-amber-100">
          {isMobile ? <MobileComponent /> : <DesktopComponent />}
        </div>

        <div className="flex  items-center mt-[30px] w-[70%] md:w-full">
          <ProductForSale
            products={latestProducts}
            heading={"Gifting Items"}
            relatedProductPage={false}
          />
        </div>
      </div>
      <div className="bg-amber-100 text-center p-2">
        <p
          className="uppercase tracking-[5px]
"
        >
          From Gifts to Essentials – Everything You Need for a Dream Wedding!
        </p>
      </div>
    </>
  );
};

const MobileComponent = () => {
  return (
    <div className="flex flex-col md:w-full relative items-center justify-center md:bg-gradient-to-b from-amber-50 to-amber-100">
      {/* Inverted triangle gradients placed behind the image */}
      <div className="absolute top-0 left-0 w-full h-full z-0 md:bg-[#edd498]">
        <div className="absolute left-0 w-[50%] h-full bg-gradient-to-r  to-[#dadada]"></div>
        <div className="absolute right-0 w-[50%] h-full bg-gradient-to-l  to-[#dadada]"></div>
      </div>

      {/* Image with higher z-index to be above the gradient */}
      <img
        src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1740054750727_1740054750723.webp"
        className="w-[300px] h-[300px] object-contain z-10"
        alt=""
      />

      {/* Text */}
      <div className="absolute top-[30%] left-[30%] md:left-[40%] text-[20px] text-center z-10">
        <p className="font-semibold text-rose-600">Under 50000</p>
        <p className="font-semibold text-teal-600">For Wedding gift</p>
      </div>
    </div>
  );
};

const DesktopComponent = () => {
  return (
    <div className="flex flex-col  relative items-center justify-center">
      {/* Image without gradient */}
      <img
        src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1740054750727_1740054750723.webp"
        className="w-full h-[400px] object-contain"
        alt=""
      />

      {/* Text */}
      <div className="absolute top-[30%] left-[20%] text-[30px] z-10">
        <p className="font-semibold text-rose-600">Under 50000</p>
        <p className="font-semibold text-teal-600">
          For <br /> Wedding <br /> gift
        </p>
      </div>
    </div>
  );
};

export default Gifting;
