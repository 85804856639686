import { FaAngleRight, FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

interface UpperBannerProps {
  categoryPath: string[] | null; // Updated to explicitly be an array of strings or null
  brands: { _id: string; name: string }[];
  brandName: string | null;
  bannerImage: string;
}

const UpperBanner = ({
  categoryPath,
  brands,
  bannerImage,
  brandName,
}: UpperBannerProps) => {
  const formatText = (text: string | null) =>
    text ? text.replace(/-/g, " ") : "";

  // Get the last category name from the categoryPath array
  const lastCategoryName =
    categoryPath && categoryPath.length > 0
      ? categoryPath[categoryPath.length - 1]
      : null;

  return (
    <>
      {/* Breadcrumb Navigation */}
      <div className="text-sm rounded-md mt-[95px] ml-2 p-[1px] bg-gray-100 lg:mt-[100px]">
        <div className="max-w-full overflow-x-auto">
          <nav className="flex items-center text-gray-600 text-[12px] whitespace-nowrap w-max px-2 space-x-1">
            <Link
              to="/"
              className="hover:text-blue-500 font-medium flex items-center"
            >
              <FaHome className="mr-1" />
              Home
            </Link>

            {categoryPath &&
              categoryPath?.length > 0 &&
              categoryPath.map((category, index) => {
                const fullPath = `/category/${categoryPath
                  .slice(0, index + 1)
                  .join("/")}`;
                return (
                  <div key={index} className="flex items-center">
                    <FaAngleRight />
                    <Link
                      to={fullPath}
                      className="text-blue-600 font-bold capitalize hover:text-blue-500"
                    >
                      {formatText(category)}
                    </Link>
                  </div>
                );
              })}

            {brandName && (
              <>
                <FaAngleRight />
                <span className="font-bold text-blue-600">
                  {formatText(brandName)}
                </span>
              </>
            )}
          </nav>
        </div>
      </div>

      {/* Banner Image Section */}
      <div className="w-full relative">
        {bannerImage && (
          <img
            className="w-full h-[250px] lg:h-full object-fit md:object-contain object-center"
            src={
              bannerImage ||
              "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739965883925_1739965883921.webp"
            }
            alt="Banner"
          />
        )}
        {!bannerImage && (
          <img
            className="w-full h-[250px] lg:h-[180px] object-cover object-center"
            src={
              "https://cayrobucket.blr1.digitaloceanspaces.com/images/1739965883925_1739965883921.webp"
            }
            alt="Banner"
          />
        )}

        {/* Category Title (Only if no banner image is available) */}
        {!bannerImage && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/40 text-white">
            <h1 className="text-3xl font-bold md:text-2xl capitalize">
              {formatText(lastCategoryName) || formatText(brandName)}
            </h1>
          </div>
        )}
      </div>
    </>
  );
};

export default UpperBanner;
