import { Link, useNavigate } from "react-router-dom";
import {
  MdKeyboardArrowDown,
  MdKeyboardVoice,
  MdLocalOffer,
} from "react-icons/md";
import { TbCategory2 } from "react-icons/tb";
import Recording from "../../loti/recording.json";
import { FaRegHeart, FaUser } from "react-icons/fa";
import { IoCartOutline } from "react-icons/io5";
import { BsFire } from "react-icons/bs";
import { useCallback, useEffect, useRef, useState } from "react";
import Category from "./Category";
import Sidebar from "./SidebarForMobile";
import { RxHamburgerMenu } from "react-icons/rx";
import { GetRequest, PostRequest } from "../../api/Request";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { logout } from "../../store/slice/UserSlice";
import { setCartLength, totalCartItem } from "../../store/slice/CartSlice";
import { debounce } from "lodash";
import Loader from "../../common/loader/Loader";
import Lottie from "lottie-react";
import { IoIosSearch } from "react-icons/io";
import { asyncGetRootCategory } from "../../store/actions/productAction";

const Header = (props: any) => {
  let { allCategory, isLoadingForCategory } = props;

  const { user } = useSelector((state: RootState) => state.user);

  const [isSpeaking, setIsSpeaking] = useState<boolean>(false); // State to track if user is speaking
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  const [allProductForSearchDropdown, setAllProductForSearchDropdown] =
    useState<any>([]);
  const [isLoadingForSearchDropdown, setIsLoadingForSearchDropdown] =
    useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showCategory, setShowCategory] = useState<boolean>(false);
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);
  const [errorForSearchingProduct, setErrorForSearchingProduct] =
    useState<string>("");
  const [showSideBarInMobile, setShowSideBarInMobile] =
    useState<boolean>(false);
  const cartItem = useSelector(totalCartItem);
  const searchRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  // Toggle dropdown visibility

  const toggleDropdown = () => {
    if (!localStorage.getItem("userToken")) {
      localStorage.setItem("isLoggedIn", "false");
      dispatch(logout());
    }
    setIsOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  const handleClickOutsideOnProfile = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Close dropdown when a link is clicked
  const handleLinkClick = () => {
    setIsOpen(false);
  };

  // Now, use the latitude and longitude once they have been set
  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      fetchUserLocation(latitude, longitude);
    }
  }, [latitude, longitude]); // This effect runs whenever latitude or longitude changes

  // Add event listener for clicks outside
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideOnProfile);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOnProfile);
    };
  }, []);

  const handleClickOnCategory = () => {
    setShowCategory((prevState: any) => {
      return !prevState;
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(asyncGetRootCategory());
  }, [dispatch]);

  const handleClickOnLogout = async () => {
    try {
      let response = await PostRequest("/user-Logout");
      if (response.status === 200) {
        localStorage.removeItem("userToken");
        navigate("/login-by-otp");
        dispatch(logout());
        dispatch(setCartLength(0));
        handleLinkClick();
        showSuccessToast("Logged out successfully");
      }
    } catch (error: any) {
      showErrorToast(error.response.data.message);
    }
  };

  const fetchUserLocation = async (
    latitude: number,
    longitude: number
  ): Promise<{ city: string; state: string }> => {
    const apiKey = "AIzaSyC4FE1dcKZW4SE-Wb_7Pp-v5AECpBK6ojY";
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;

        let city = "";
        let state = "";

        // Loop through address components to find city and state
        addressComponents.forEach((component: any) => {
          if (component.types.includes("locality")) {
            city = component.long_name; // City
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name; // State
          }
        });

        return { city, state };
      }

      return { city: "", state: "" }; // Return empty values if no results
    } catch (error) {
      console.error("Error fetching location:", error);
      return { city: "", state: "" }; // Return empty values in case of an error
    }
  };

  const debouncedSearchProduct = useCallback(
    debounce(async (query) => {
      setIsSpeaking(false);
      setIsLoadingForSearchDropdown(true);

      if (query) {
        try {
          const userId = user?._id; // Get the user ID

          let city = "";
          let state = "";

          // Fetch location if latitude and longitude are available
          if (latitude && longitude) {
            const location = await fetchUserLocation(latitude, longitude);
            city = location.city;
            state = location.state;
          }

          let response;

          // Handle API calls based on the availability of userId and location
          if (userId && city && state) {
            // Scenario: Location is on and user is logged in
            response = await GetRequest(
              `/searchProduct/${query}?userId=${userId}&city=${city}&state=${state}`
            );
          } else if (!userId && city && state) {
            // Scenario: Location is on and user is not logged in
            response = await GetRequest(
              `/searchProduct/${query}?city=${city}&state=${state}`
            );
          } else if (!userId && (!city || !state)) {
            // Scenario: Location is off and user is not logged in
            response = await GetRequest(`/searchProduct/${query}`);
          } else if (userId && (!city || !state)) {
            // Scenario: Location is off and user is logged in
            response = await GetRequest(
              `/searchProduct/${query}?userId=${userId}`
            );
          }

          // Process API response
          if (response && response.data) {
            setErrorForSearchingProduct("");

            // Extract and process products
            const filteredProducts =
              response.data.length > 0 ? response.data : response.data.products;

            const modifiedProducts = filteredProducts.map((product: any) => {
              const productName = product.productName.toLowerCase();
              const queryLower = query.toLowerCase();

              // Highlight matched substring in product names
              const matchIndex = productName.indexOf(queryLower);
              if (matchIndex !== -1) {
                const matchedSubstring = productName.slice(
                  matchIndex,
                  matchIndex + query.length + 10
                );
                return { ...product, matchedSubstring };
              }
              return product;
            });

            setAllProductForSearchDropdown(modifiedProducts);
          } else {
            // Handle cases where no products are found or data is undefined
            setAllProductForSearchDropdown([]);
            setErrorForSearchingProduct(
              "No products found or failed to fetch data."
            );
          }
        } catch (error: any) {
          // Handle errors in API call
          if (error?.response?.status === 404) {
            setAllProductForSearchDropdown([]);
            setErrorForSearchingProduct(error.response.data.message);
          } else {
            setErrorForSearchingProduct(
              "An error occurred while searching for products."
            );
          }
        }
      }

      setIsLoadingForSearchDropdown(false);
    }, 500), // Debounce time is 500 ms
    [latitude, longitude, user]
  );

  useEffect(() => {
    debouncedSearchProduct(searchQuery);

    return () => {
      debouncedSearchProduct.cancel();
    };
  }, [searchQuery, debouncedSearchProduct]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchQuery(value);

    if (value.length > 0) {
      const filteredProducts = allProducts.filter((product: any) =>
        product.productName.toLowerCase().includes(value.toLowerCase())
      );

      setAllProductForSearchDropdown(filteredProducts);
      setErrorForSearchingProduct("");
    } else {
      setAllProductForSearchDropdown([]);
      setErrorForSearchingProduct("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission behavior

      // Check if searchQuery is empty
      if (!searchQuery.trim()) {
        return; // Do nothing if the search query is empty
      }

      // Reset search dropdown
      setAllProductForSearchDropdown([]);
      // Navigate to the search page with the query and page parameters
      navigate(`/search/${encodeURIComponent(searchQuery)}?page=1`);
    }
  };

  const handleVoiceSearch = async (): Promise<void> => {
    const SpeechRecognition =
      window.SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true; // Keep listening
      recognition.interimResults = true;

      let inactivityTimer: NodeJS.Timeout;

      // Function to reset the inactivity timer
      const resetInactivityTimer = (): void => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(() => {
          recognition.stop(); // Stop recognition after 5 seconds of inactivity
          showInfoToast("🤔 Still thinking what to search");
        }, 4000); // 5000 milliseconds = 5 seconds
      };

      recognition.onstart = (): void => {
        setIsSpeaking(true); // User has started speaking
        resetInactivityTimer(); // Start the inactivity timer
      };

      recognition.onresult = (event: any): void => {
        const transcript = event.results[event.resultIndex][0].transcript;
        setSearchQuery(transcript); // Set recognized text in the search box
        setIsSpeaking(() => false);
        clearTimeout(inactivityTimer);
        // resetInactivityTimer();
        // Reset the inactivity timer on speech input
      };

      recognition.onerror = (event: any): void => {
        setIsSpeaking(false);
        // showErrorToast("Speech recognition error: " + event.error);
      };

      recognition.onend = (): void => {
        setIsSpeaking(false); // User has stopped speaking
        clearTimeout(inactivityTimer); // Clear the timer when recognition ends
      };

      recognition.start();
    } else {
      alert("Speech recognition is not supported in this browser.");
    }
  };

  const handleClickOutside = (event: { target: any }) => {
    if (searchRef.current && !searchRef?.current?.contains(event.target)) {
      setIsSpeaking(false); // User
      setAllProductForSearchDropdown(() => {
        return [];
      });
      setIsLoadingForSearchDropdown(() => false);
      setErrorForSearchingProduct(() => "");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const generateCategoryMetaTags = () => {
    if (!allCategory || allCategory.length === 0) return null;

    // Generate individual meta tags for each category
    return allCategory.map((category: any) => {
      const metaDescription =
        category?.metaInfo?.metaInfoDescription ||
        ` ${category?.category || "products"} collection${
          category?.metaInfo?.metaInfoKeyword?.length > 0
            ? `,${category.metaInfo.metaInfoKeyword.join(", ")}`
            : ""
        }`;

      return (
        <meta
          key={`${category._id}`}
          name={`${category.category}`}
          content={metaDescription}
        />
      );
    });
  };

  // Use it inside Helmet

  return (
    <header className="bg-primary p-[1.5rem] lg:p-[0.5rem]  w-[100%] fixed top-0 z-[99999] text-[white] sm:!pb-[0.7rem]">
      <div className="flex justify-between w-full flex-wrap items-center">
        <div className="flex items-center basis-[20%]">
          {screenSize <= 600 && (
            <div className="text-center">
              <button
                onClick={() => setShowSideBarInMobile(true)}
                className="text-white bg-transparent font-medium rounded-lg text-sm mt-[0.3rem] mr-[0.5rem]"
                type="button"
                data-drawer-target="drawer-body-scrolling"
                data-drawer-show="drawer-body-scrolling"
                data-drawer-body-scrolling="true"
                aria-controls="drawer-body-scrolling"
              >
                <RxHamburgerMenu className="text-[1.3rem]" />
              </button>
            </div>
          )}

          <Link to={"/"} className="flex items-center  w-[110px]">
            {/* <span className="text-[white] text-[1.8rem] sm:text-[1rem] mb-[0.3rem] sm:mb-[0rem] font-bold tracking-wide">
              <img src="/asset/logo/cayro" alt="" />
              <p className="header-logo">Cayro</p>
            </span> */}
            <img
              src="https://cayrobucket.blr1.cdn.digitaloceanspaces.com/images/cayro_nav_logo.png"
              alt="cayroshop"
              className="h-[25px]"
            />
            <img
              className="w-[80px]"
              src={`https://cayrobucket.blr1.digitaloceanspaces.com/images/1739957370020_1739957370013.png`}
              alt="cayro logo"
            />
          </Link>
          <div
            className="flex lg:hidden items-center cursor-pointer ml-[5rem]"
            onClick={handleClickOnCategory}
          >
            <TbCategory2 className="white ml-[1rem] mr-[0.5rem]" />
            Categories
            <MdKeyboardArrowDown className="ml-[0.3rem]" />
          </div>
        </div>

        <div className="relative basis-[36%] lg:mt-[0.4rem] lg:basis-[100%] flex justify-center ">
          <div className="relative text-gray-600 focus-within:text-gray-400 w-full  sm:basis-1/2 md:basis-1/2 lg:basis-full">
            {
              <span
                className={
                  isLoadingForSearchDropdown
                    ? "absolute left-[-8px] bottom-[44px] flex items-center pl-2"
                    : "absolute left-[-3px] bottom-[9px] lg:bottom-[4px] flex items-center pl-2"
                }
              >
                {isLoadingForSearchDropdown ? (
                  <Loader />
                ) : (
                  <button
                    type="submit"
                    onClick={() => debouncedSearchProduct(searchQuery)}
                    className="p-1 focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-6 h-6 text-[#a8a8a8]"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </button>
                )}
              </span>
            }

            <div className="relative flex items-center rounded-l-[10px] lg:rounded-r-[10px] bg-white">
              <IoIosSearch className="m-2 h-8 w-8" />
              <input
                type="text"
                onChange={handleSearch}
                onKeyDown={handleKeyDown}
                value={searchQuery}
                name="searchQuery"
                className="py-[0.9rem] w-full sm:py-[0.6rem] text-sm lg:rounded-md text-[black] pr-[6px] rounded-l-md focus:outline-none"
                placeholder="Search your product"
                autoComplete="off"
              />
              <button
                className={`flex items-center justify-center text-[26px] ml-2 ${
                  isSpeaking ? "text-red-500" : "text-gray-500"
                }`}
                onClick={handleVoiceSearch}
              >
                {isSpeaking ? (
                  <Lottie
                    animationData={Recording}
                    loop={true}
                    className="w-[3rem] h-[3rem] sm:w-[2rem]"
                  />
                ) : (
                  <MdKeyboardVoice />
                )}
              </button>
            </div>
          </div>
          {/* search bar dropdown */}
          {errorForSearchingProduct ||
          allProductForSearchDropdown.length > 0 ? (
            <div
              className="absolute w-full right-0 z-10 mt-2 top-[44px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              ref={searchRef}
              aria-orientation="vertical"
              aria-labelledby="menu-button"
            >
              <div
                className="py-1 max-h-[230px] overflow-scroll no-scrollbar"
                role="none"
              >
                {errorForSearchingProduct && (
                  <p className="flex justify-center items-center m-[0.4rem] pb-[0.4rem] text-sm text-red-500">
                    {errorForSearchingProduct}
                  </p>
                )}

                {allProductForSearchDropdown.length > 0 &&
                  allProductForSearchDropdown
                    .slice(0, 20)
                    .map((product: any) => {
                      const searchTerm = searchQuery.toLowerCase();
                      const productName = product.productName.toLowerCase();
                      const matchedSubstring =
                        product.matchedSubstring || productName; // Use processed substring if available

                      // Highlight the matched portion of the product name
                      let highlightedSuggestion = matchedSubstring
                        .split("")
                        .map((char: any, index: number) => {
                          if (char.toLowerCase() === searchTerm.charAt(index)) {
                            return `<span class="text-blue-500">${char}</span>`; // Highlight matched part
                          }
                          return char;
                        })
                        .join("");

                      return (
                        <div
                          key={product.id || product._id}
                          className="mb-[0.4rem] text-gray-700 cursor-pointer p-2"
                          onClick={(e) => {
                            setSearchQuery("");
                            setAllProductForSearchDropdown([]);

                            // Format product name: replace spaces with "-" and remove commas
                            let clickedText = (e.target as HTMLElement)
                              .innerText;
                            clickedText = clickedText
                              .replace(/,/g, "") // Remove commas
                              .replace(/\s+/g, "-");
                            navigate(
                              `/search/${encodeURIComponent(clickedText)}`
                            );
                          }}
                          dangerouslySetInnerHTML={{
                            __html: highlightedSuggestion,
                          }}
                        />
                      );
                    })}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="flex items-center lg:hidden basis-[50%] z-[88998] bg-white pr-[6px] rounded-r-md  focus:outline-none justify-evenly">
            <Link
              to="/category/daily-deal"
              className="bg-[#EDF1F4] p-[0.5rem] text-[0.75rem] text-[#08091b] rounded-lg w-[100px] hover:bg-gray-200"
            >
              <MdLocalOffer className="inline text-green-400" /> Daily deals
            </Link>
            <Link
              to="/category/hot-deal"
              className="bg-[#EDF1F4] ml-[0.3rem] p-[0.5rem] text-[0.75rem] text-[#08091b] rounded-lg w-[100px] hover:bg-gray-200"
            >
              <BsFire className="inline text-[red]" /> Hot deals
            </Link>
          </div>
        </div>

        <div className="flex items-center basis-[28%] justify-center  lg:absolute lg:top-[9px] lg:right-[15px] ">
          {isLoggedIn ? (
            <Link
              to="/profile"
              className="mr-[0.7rem] sm:mr-0 sm:hidden sm:text-[0.9rem]"
              onClick={() => {
                if (!localStorage.getItem("userToken")) {
                  localStorage.setItem("isLoggedIn", "false");
                  dispatch(logout());
                }
              }}
            >
              Profile
            </Link>
          ) : null}

          {!isLoggedIn ? (
            <Link
              to="/login-by-otp"
              onClick={handleLinkClick}
              className="block px-4 py-2 text-sm text-gray-700"
              role="menuitem"
              id="menu-item-1"
            >
              <p className="text-white">Login</p>
            </Link>
          ) : (
            <div className="relative">
              <p
                onClick={toggleDropdown}
                className="text-[0.9rem] sm:!inline-block cursor-pointer  xxl:hidden mr-[0.9rem]"
              >
                <FaUser />
              </p>
              {isOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute sm:w-[130px] right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                >
                  <div className="py-1" role="none">
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      id="menu-item-2"
                      onClick={handleLinkClick}
                    >
                      {/* <FaUser /> */}
                      Profile
                    </Link>
                    <Link
                      to="/order-info"
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      onClick={handleLinkClick}
                      id="menu-item-2"
                    >
                      {/* <FaFirstOrder className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900" />{" "} */}
                      Your Orders
                    </Link>
                    <Link
                      to="/list-of-address"
                      className="block px-4 py-2 text-sm text-gray-700"
                      role="menuitem"
                      id="menu-item-2"
                      onClick={handleLinkClick}
                    >
                      {/* <FaLocationArrow className="flex-shrink-0 w-5 h-5 text-primary transition duration-75 text-primary group-hover:text-gray-900" /> */}
                      Address
                    </Link>
                    <button
                      type="submit"
                      className="block w-full px-4 py-2 text-left text-sm text-gray-700"
                      role="menuitem"
                      id="menu-item-3"
                      onClick={handleClickOnLogout}
                    >
                      Log out
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          <Link to="/wishlist">
            <FaRegHeart className="text-lg text-[1.5rem] sm:text-[1.1rem]" />
          </Link>
          {/* cart items with count */}
          <Link to="/cart" className="relative sm:text-[0.9rem]">
            <IoCartOutline className="text-[1.7rem] sm:text-[1.5rem] font-bold ml-[0.9rem]" />
            <div className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-whitesmoke bg-red-500 rounded-full -top-1 -end-2">
              {cartItem}
            </div>
          </Link>

          <div className="wrap flex items-center justify-center ml-[25px]   gap-[20px] smm:hidden">
            <img
              src="https://cayrobucket.blr1.digitaloceanspaces.com/images/1740223549527_1740223549523.webp"
              className="w-[70px] lg:w-[60px]  mt-0"
              alt=""
            />

            <Link to="/cayrostore" className="relative cayrostore-link ">
              Cayrostore
            </Link>
          </div>
        </div>
      </div>
      {showSideBarInMobile && (
        <Sidebar setShowSideBarInMobile={setShowSideBarInMobile} />
      )}
      {showCategory && (
        <div className="absolute top-[90%] left-[14%]">
          <Category
            setShowCategory={setShowCategory}
            isLoadingForCategory={isLoadingForCategory}
          />
        </div>
      )}
    </header>
  );
};

export default Header;
