import Slider from "@mui/material/Slider";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";

const Filters = ({
  setShowFilterOnMobile,
  productFilter,
  showFilterOnMobile,
  handleCheckboxChange,
  handlePriceChange,
  priceRange,
  handleClickOnClearAllFilter,
  isMobile,
  showMoreState,
  isCheckedOrNot,
  toggleShowMore,
}: any) => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  // Helper function to get filter label based on key
  const getFilterLabel = (key: string, item: any) => {
    if (key === "Brands") {
      return item.name;
    }
    return item;
  };

  // Handle category click and open in new tab
  const handleCategoryClick = (category: { slug: string }) => {
    const currentPath = window.location.pathname; // Get the current URL path
    const newPath = `${currentPath}/${category.slug}`; // Append the clicked category slug
    setActiveCategory(category.slug); // Update the active category for UI feedback
    window.open(newPath, "_blank"); // Open the new path in a new tab
  };

  return (
    <div
      className={
        showFilterOnMobile
          ? "fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-10 flex justify-center items-center"
          : "basis-[20%] md:basis-0"
      }
    >
      <aside
        className={
          showFilterOnMobile
            ? "top-[101px] max-h-[57vh] w-[74%] absolute overflow-y-auto border-[1.3px] border-solid border-[#ddd] rounded-lg px-[0.8rem] mt-[0.75rem] bg-[#F7F4FF] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
            : "basis-[20%] sm:hidden max-h-[1400px] lg:max-h-full xl:max-h-full xxl:max-h-[1300px] overflow-y-auto border-[1.3px] border-solid border-[#ddd] rounded-lg px-[0.4rem] mt-[0.75rem] bg-[#F7F4FF] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
        }
      >
        {Object.entries(productFilter).length > 0 && (
          <p
            className={
              isMobile
                ? "flex justify-between items-center mt-[0.5rem]"
                : "flex justify-end items-center"
            }
          >
            <span
              onClick={handleClickOnClearAllFilter}
              className="text-sm text-[#3E0E9A] mt-[0.5rem] text-end cursor-pointer"
            >
              Reset All
            </span>
            {isMobile && (
              <span
                onClick={() => setShowFilterOnMobile(false)}
                className="text-[1.2rem] text-red-600 mt-[0.5rem] text-end cursor-pointer"
              >
                <RxCross2 />
              </span>
            )}
          </p>
        )}
        <ul className="space-y-2 font-medium py-4">
          {Object.entries(productFilter).length > 0 && (
            <>
              {/* Price Range Filter */}
              {productFilter.maxPrice && (
                <li className="relative">
                  <div className="p-1 rounded-lg">
                    <span className="text-[0.95rem] font-semibold text-gray-900">
                      Price Range
                    </span>
                  </div>
                  <div className="mt-2 px-3">
                    <div className="relative flex flex-col items-center w-full">
                      <Slider
                        value={priceRange ?? [0, productFilter.maxPrice]}
                        onChange={handlePriceChange}
                        valueLabelDisplay="auto"
                        min={productFilter.minPrice}
                        max={productFilter.maxPrice}
                        sx={{
                          color: "#3F0E9E",
                          height: 6,
                          "& .MuiSlider-thumb": {
                            width: 16,
                            height: 16,
                            backgroundColor: "#3F0E9E",
                            border: "2px solid #fff",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                          },
                          "& .MuiSlider-track": {
                            height: 4,
                            background:
                              "linear-gradient(to right, #3F0E9E, #6B46C1)",
                          },
                          "& .MuiSlider-rail": {
                            height: 4,
                            backgroundColor: "#e5e7eb",
                            opacity: 0.8,
                          },
                        }}
                      />
                      <div className="flex justify-between w-full text-xs text-gray-600 mt-2">
                        <span>₹{priceRange?.[0]}</span>
                        <span>₹{priceRange?.[1]}</span>
                      </div>
                    </div>
                  </div>
                  <hr className="my-2 border-gray-200" />
                </li>
              )}

              {/* Categories Filter */}
              {productFilter.childCategories && (
                <li className="relative">
                  <div className="p-1 rounded-lg">
                    <span className="text-[0.95rem] font-semibold text-gray-900">
                      {productFilter.childCategories?.[0]?.parentsCategory[0]
                        ?.name || "Categories"}
                    </span>
                  </div>
                  <ul className="mt-1 grid grid-cols-1">
                    {productFilter.childCategories.map(
                      (category: any, idx: number) => (
                        <li
                          key={idx}
                          className={`px-1 ml-5 py-1 text-sm transition-colors cursor-pointer ${
                            activeCategory === category.slug
                              ? "text-[#3F0E9E] font-semibold bg-gray-100 rounded-md"
                              : "text-gray-700 hover:bg-gray-100 hover:text-[#3F0E9E] rounded-md"
                          }`}
                          onClick={() => handleCategoryClick(category)}
                        >
                          <span className="truncate">{category.name}</span>
                        </li>
                      )
                    )}
                  </ul>
                  <hr className="my-2 border-gray-200" />
                </li>
              )}

              {/* Other Filters */}
              {Object.entries(productFilter)
                .filter(
                  ([key]) =>
                    key !== "childCategories" &&
                    key !== "maxPrice" &&
                    key !== "minPrice"
                )
                .map(([key, value]) => {
                  const items = Array.isArray(value) ? value : [];
                  const visibleItems = showMoreState[key]
                    ? items
                    : items.slice(0, 7);
                  const hasMoreItems = items.length > 7;

                  return (
                    <li key={key} className="relative">
                      <div className="p-1 rounded-lg">
                        <span className="text-[0.95rem] font-semibold text-gray-900">
                          {key === "Display Quality" ? "Display Quality" : key}
                        </span>
                      </div>
                      <ul className="mt-1 grid grid-cols-1 ml-4">
                        {visibleItems.map((item, idx) => (
                          <li
                            key={idx}
                            className="px-1 py-1 text-gray-700 hover:bg-gray-100 rounded-md text-sm transition-colors"
                          >
                            <label className="flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                checked={isCheckedOrNot(
                                  key,
                                  key === "Brands" ? item._id : item
                                )}
                                onChange={() =>
                                  handleCheckboxChange(
                                    key,
                                    key === "Brands" ? item._id : item
                                  )
                                }
                                className="mr-2 h-4 w-4 text-purple-600 border-gray-300 rounded focus:ring-purple-500"
                              />
                              <span className="truncate">
                                {getFilterLabel(key, item)}
                              </span>
                            </label>
                          </li>
                        ))}
                        {hasMoreItems && (
                          <li className="px-3 py-2 text-sm text-blue-600 hover:text-blue-800 cursor-pointer">
                            <button onClick={() => toggleShowMore(key)}>
                              {showMoreState[key] ? "Show Less" : "Show More"}
                            </button>
                          </li>
                        )}
                      </ul>
                      <hr className="my-2 border-gray-200" />
                    </li>
                  );
                })}
            </>
          )}
        </ul>
      </aside>
    </div>
  );
};

export default Filters;
