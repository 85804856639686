import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaShoppingCart } from "react-icons/fa";
import Loader from "../../common/loader/Loader";
import {
  filters,
  isLoadingForProduct,
  metaInfo,
  totalItemForProduct,
  updateSelectedFilter,
} from "../../store/slice/ProductSlice";
import { AppDispatch, RootState } from "../../store/store";
import Pagination from "../../pagination/Pagination";
import { useMediaQuery } from "@react-hook/media-query";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { RenderHelmetForCat } from "../../utils/Helmet";
import UpperBanner from "./UpperBanner";
import BottomDesc from "./BottomDesc";
import {
  asyncAddToCart,
  asyncFetchFilterFormattedBrand,
  asyncFetchFilterFormattedCatAndSubCat,
  asyncGetProductByFilters,
  asyncUpdateQuantity,
} from "../../store/actions/productAction";
import { asyncRemoveItem } from "../../store/actions/searchProductAction";
import { FiMinus, FiPlus } from "react-icons/fi";
import Filters from "./Filter";

const ProductListing = () => {
  const metaInfoForProductListing = useSelector(metaInfo);
  const categoryDescription = useSelector(
    (state: RootState) => state.product.categoryDescription
  );
  const isMobile = useMediaQuery("(max-width: 780px)");
  const [userChangedPrice, setUserChangedPrice] = useState(false);

  const [, setLoadingProducts] = useState<{
    [key: string]: boolean;
  }>({});
  const totalItems = useSelector(totalItemForProduct);
  const navigate = useNavigate();
  const productFilter = useSelector(filters);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const query: any = new URLSearchParams(location.search);

  // Extract path information
  const pathSegments = location.pathname.split("/").filter(Boolean); // e.g., ["category", "home-appliances", "washing-machine", "top-load-washing-machine"]
  const isCategoryRoute = pathSegments[0] === "category";
  const isBrandRoute = pathSegments[0] === "brand";
  const categoryPath = isCategoryRoute ? pathSegments.slice(1) : []; // e.g., ["home-appliances", "washing-machine", "top-load-washing-machine"]
  const lastCategoryName =
    categoryPath.length > 0 ? categoryPath[categoryPath.length - 1] : null; // e.g., "top-load-washing-machine"
  const brandName = isBrandRoute ? pathSegments[1] : null; // e.g., "brandName"

  const { allProduct, setMessage, bannerImage } = useSelector(
    (state: RootState) => state.product
  );
  const isLoading = useSelector(isLoadingForProduct);
  const [showFilterOnMobile, setShowFilterOnMobile] = useState<boolean>(false);
  const [loadingProductId, setLoadingProductId] = useState<string | null>(null);
  const [cart, setCart] = useState<{ [key: string]: number }>({});
  const [sortBy, setSortBy] = useState("");

  const useDebounce = (value: any, delay: any) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const [priceRange, setPriceRange] = useState<[number, number] | undefined>(
    undefined
  );

  useEffect(() => {
    if (
      productFilter?.minPrice !== undefined &&
      productFilter?.maxPrice !== undefined
    ) {
      setPriceRange([productFilter.minPrice, productFilter.maxPrice]);
    }
  }, [productFilter]);

  const debouncedPriceRange = useDebounce(priceRange, 2000);

  useEffect(() => {
    if (!userChangedPrice || !debouncedPriceRange) return;

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("minPrice", String(debouncedPriceRange[0]));
    searchParams.set("maxPrice", String(debouncedPriceRange[1]));

    navigate(`?${searchParams.toString()}`);
  }, [debouncedPriceRange, navigate, location.search, userChangedPrice]);

  const handlePriceChange = (_event: Event, newValue: number | number[]) => {
    setPriceRange(newValue as [number, number]);
    setUserChangedPrice(true);
  };

  const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortBy(event.target.value);
  };

  useEffect(() => {
    const brandId: any = query.get("brand-id");
    const searchParams = new URLSearchParams(location.search);
    const filters: { [key: string]: any } = {};
    const minPrice = searchParams.get("minPrice");
    const maxPrice = searchParams.get("maxPrice");
    const page = searchParams.get("page");
    searchParams.forEach((value, key) => {
      if (
        [
          "category_id",
          "sub-category-id",
          "page",
          "categoryName",
          "subcategoryName",
          "minPrice",
          "maxPrice",
        ].includes(key.toLowerCase())
      ) {
        return;
      }
      if (searchParams.getAll(key).length > 1) {
        filters[key] = searchParams.getAll(key);
      } else {
        filters[key] = value;
      }
    });

    dispatch(
      asyncGetProductByFilters({
        navigate,
        categoryIdForProduct: isCategoryRoute ? lastCategoryName : undefined,
        ...filters,
        maxPrice: maxPrice || undefined,
        minPrice: minPrice || undefined,
        sortBy,
        pageForProduct: page || 1,
        brandId: isBrandRoute ? brandId : undefined,
      })
    );

    window.scrollTo(0, 0);
  }, [
    location.search,
    dispatch,
    sortBy,
    lastCategoryName,
    isCategoryRoute,
    isBrandRoute,
  ]);

  useEffect(() => {
    const brandId: any = query.get("brand-id");
    if (isCategoryRoute && lastCategoryName) {
      dispatch(asyncFetchFilterFormattedCatAndSubCat(lastCategoryName));
    }
    if (isBrandRoute && brandName) {
      dispatch(asyncFetchFilterFormattedBrand(brandId));
    }
  }, [
    lastCategoryName,
    brandName,
    query.get("brand-id"),
    dispatch,
    isCategoryRoute,
    isBrandRoute,
  ]);

  const filterContainerRef = useRef<HTMLDivElement>(null);
  const preserveScrollPosition = () => {
    // Use optional chaining to safely access scrollTop
    const scrollPosition =
      filterContainerRef.current?.scrollTop || window.scrollY;
    return () => {
      if (filterContainerRef.current) {
        filterContainerRef.current.scrollTop = scrollPosition;
      } else {
        window.scrollTo(0, scrollPosition);
      }
    };
  };

  

  const handleCheckboxChange = (filterField: string, filterValue: any) => {
    const restoreScroll = preserveScrollPosition();
    dispatch(updateSelectedFilter({ filterField, filterValue }));

    const searchParams = new URLSearchParams(location.search);

    const updateSearchParams = (key: string, value: any) => {
      let existingValues: string[];
      let lowercasedValue = value;

      if (key === "Brands") {
        existingValues = searchParams.getAll("brand");
        key = "brand";
        lowercasedValue = value.toLowerCase();
      } else {
        existingValues = searchParams.getAll(key);
      }

      if (!existingValues.includes(lowercasedValue)) {
        searchParams.append(key, lowercasedValue);
      } else {
        const updatedValues = existingValues.filter(
          (item) => item !== lowercasedValue
        );
        searchParams.delete(key);
        updatedValues.forEach((item) => searchParams.append(key, item));
      }
    };

    updateSearchParams(filterField, filterValue);

    navigate(`?${searchParams.toString()}`);
    requestAnimationFrame(restoreScroll);
  };

  const isCheckedOrNot = (key: string, item: any) => {
    const searchParams = new URLSearchParams(location.search);
    const normalizedKey = key === "Brands" ? "brand" : key;
    const existingValues = searchParams.getAll(normalizedKey);
    return existingValues.includes(item);
  };

  const handleAddToCart = async (product: any, index: number) => {
    const productId = product._id;
    setLoadingProductId(productId);
    setLoadingProducts((prev) => ({ ...prev, [productId]: true }));
    let flag = true;
    try {
      await dispatch(asyncAddToCart(product, flag));
      setLoadingProducts((prev) => ({ ...prev, [productId]: false }));
      setCart((prevCart) => ({ ...prevCart, [productId]: 1 }));
    } catch (error) {
      console.error("Failed to add product to cart:", error);
    } finally {
      setLoadingProductId(null);
    }
  };

  const handleUpdateQuantity = async (
    product: any,
    index: number,
    quantity: number
  ) => {
    const productId = product._id;
    setLoadingProductId(productId);

    try {
      if (quantity === 0) {
        await dispatch(asyncRemoveItem(product));
        setCart((prevCart: any) => ({ ...prevCart, [productId]: quantity }));
      } else {
        await dispatch(asyncUpdateQuantity(product, index, quantity));
        setCart((prevCart: any) => ({ ...prevCart, [productId]: quantity }));
      }
    } catch (error) {
      console.error("Failed to update quantity:", error);
    } finally {
      setLoadingProductId(null);
    }
  };

  const [showMoreState, setShowMoreState] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleShowMore = (key: string) => {
    const restoreScroll = preserveScrollPosition();
    setShowMoreState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickOnFilter = () => {
    setShowFilterOnMobile(() => true);
  };

  const handleClickOnClearAllFilter = () => {
    if (isCategoryRoute && categoryPath.length > 0) {
      navigate(`/category/${categoryPath.join("/")}?page=1`);
    } else if (isBrandRoute && brandName) {
      navigate(`/brand/${brandName}?page=1`);
    }
    setShowFilterOnMobile(false);
  };

  const calculateDiscountPercentage = (
    salePrice: number,
    regularPrice: number
  ) => {
    const discountPercentage =
      ((regularPrice - salePrice) / regularPrice) * 100;
    return discountPercentage.toFixed(2);
  };

  return (
    <>
      {isCategoryRoute && categoryPath.length > 0 && (
        <RenderHelmetForCat metaInformations={metaInfoForProductListing} />
      )}

      <UpperBanner
        categoryPath={categoryPath}
        brands={productFilter.Brands}
        brandName={brandName}
        bannerImage={bannerImage}
      />
      <div className="max-w-[95%] sm:w-[100%] mx-auto relative sm:mt-[2rem] p-2 sm:p-0">
        <div className="catalog-toolbar flex justify-between items-center space-x-12 sm:space-x-2">
          <button
            className="mobile-catalog-toolbar__filter-button sm:sm:text-[0.8rem] bg-transparent text-[#505050] border-0 flex items-center mr-2"
            data-toggle="off-canvas"
            data-target="mobile-filter-sidebar-panel"
            onClick={isMobile ? handleClickOnFilter : () => {}}
          >
            <span className="motta-svg-icon motta-svg-icon--filter">
              <svg
                width="20"
                height="20"
                aria-hidden="true"
                role="img"
                focusable="false"
                viewBox="0 0 32 32"
              >
                <path d="M8 14.4h3.2v-9.6h-3.2v3.2h-4.8v3.2h4.8z"></path>
                <path d="M24 17.6h-3.2v9.6h3.2v-3.2h4.8v-3.2h-4.8z"></path>
                <path d="M14.4 8h14.4v3.2h-14.4v-3.2z"></path>
                <path d="M3.2 20.8h14.4v3.2h-14.4v-3.2z"></path>
              </svg>
            </span>
            Filter
            <span className="count ml-8"></span>
          </button>

          <p className="sm:text-[0.8rem] sm:hidden text-nowrap text-gray-600">
            <p className="font-semibold">
              Search Listing (Showing{" "}
              <span className="font-semibold">{allProduct.length} </span>{" "}
              products of
              <span className="font-semibold"> {totalItems}</span> products)
            </p>
          </p>
          <div className="flex items-center sm:text-[0.8rem] space-x-2 outline-none text-nowrap">
            <span>Sort by :</span>

            <select
              className="orderby p-2 sm:p-0 rounded outline-none"
              aria-label="Shop order"
              value={sortBy}
              onChange={handleSortChange}
            >
              <option value="">Default</option>
              <option value="priceAsc">Low to High</option>
              <option value="priceDesc">High to Low</option>
              <option value="date">Latest</option>
              <option value="bestSellers">Best Sellers</option>
            </select>
          </div>
        </div>
        <hr className="sm:mb-[0.5rem] mt-1" />

        <div className="flex fle=x-wrap justify-between w-[100%] sm:justify-start max-h-full">
          <Filters
            showFilterOnMobile={showFilterOnMobile}
            productFilter={productFilter}
            isMobile={isMobile}
            setShowFilterOnMobile={setShowFilterOnMobile}
            handleClickOnClearAllFilter={handleClickOnClearAllFilter}
            priceRange={priceRange}
            handlePriceChange={handlePriceChange}
            toggleShowMore={toggleShowMore}
            isCheckedOrNot={isCheckedOrNot}
            handleCheckboxChange={handleCheckboxChange}
            showMoreState={showMoreState}
          />

          <div className="flex flex-wrap justify-evenly pb-8 basis-[80%] sm:basis-full sm:pb-4">
            {isLoading ? (
              <div className="absolute top-[30%] sm:static sm:text-center sm:w-[100%] sm:flex sm:justify-center sm:items-center sm:h-[50vh] sm:left-[45%] left-[60%]">
                <Loader />
              </div>
            ) : allProduct.length > 0 ? (
              allProduct.map((product: any, index: number) => {
                const productId = product._id;
                const quantity = cart[productId] || 0;
                return (
                  <div
                    key={index}
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                    className="m-[0.7rem] max-h-[400px] sm:m-[0.4rem] basis-[22%] sm:py-[0.7rem] sm:px-[0.55rem] py-[1rem] pt-[0.5rem] px-[0.7rem] rounded-xl flex flex-col sm:basis-[42%] sm:w-[calc(50%-1.4rem)] relative"
                  >
                    <Link
                      to={`/product?name=${product?.slug}&product_id=${product._id}`}
                      className="flex sm:mt-[0.9rem] justify-center items-center"
                    >
                      <h4 className="!text-[#CD0B39] font-semibold ml-[0.3rem] !text-sm absolute right-1 z-[9] top-1 bg-[#F8F4FF] p-[2px] rounded-md">
                        {calculateDiscountPercentage(
                          Number(product?.salePrice),
                          Number(product?.regularPrice)
                        ) + "% off"}
                      </h4>
                      <LazyLoadImage
                        effect="blur"
                        src={
                          product?.images?.length > 0 && product?.images[0]?.url
                        }
                        alt={product.productName}
                        className="object-contain w-[100%] h-[200px] sm:h-[120px] transition duration-300 ease-in-out hover:scale-105"
                      />
                    </Link>
                    <Link
                      to={`/product?name=${product?.slug}&product_id=${product._id}`}
                    >
                      <h4 className="text-normal w-[220px] sm:w-[auto] line-clamp-3 text-left mt-[0.5rem] sm:text-[0.9rem] text-gray-900 h-[3rem] overflow-hidden">
                        {product.productName}
                      </h4>
                    </Link>
                    <div className="text-[1rem] sm:text-[0.9rem] text-[#0F1111] font-semibold text-left mt-1">
                      <h2>
                        ₹{product?.salePrice}
                        <h3 className="text-gray-500 text-xs ml-[0.4rem] inline-block line-through">
                          ₹{product?.regularPrice}
                        </h3>
                      </h2>
                    </div>
                    {product.totalStock === 0 ? (
                      <div className="mt-[20px] flex justify-center items-center cursor-not-allowed w-full">
                        <button className="bg-gray-400 text-white text-center w-3/4 sm:h-[25px] relative rounded-lg p-[0.4rem] sm:py-[0.2rem] text-sm sm:text-[0.63rem] cursor-not-allowed">
                          Out Of Stock
                        </button>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center gap-5 justify-center w-full">
                        {quantity > 0 ? (
                          <div className="flex items-center justify-between w-3/4 rounded-lg p-1 mt-[20px]">
                            <button
                              onClick={() =>
                                handleUpdateQuantity(
                                  product,
                                  index,
                                  quantity - 1
                                )
                              }
                              className="p-1 rounded-full border bg-gray-200 hover:bg-gray-300 transition disabled:opacity-50"
                              disabled={loadingProductId === productId}
                            >
                              <FiMinus size={16} />
                            </button>
                            <span className="text-lg font-semibold">
                              {quantity}
                            </span>
                            <button
                              onClick={() =>
                                handleUpdateQuantity(
                                  product,
                                  index,
                                  quantity + 1
                                )
                              }
                              className="p-1 rounded-full border bg-gray-200 hover:bg-gray-300 transition disabled:opacity-50"
                              disabled={
                                loadingProductId === productId ||
                                quantity >= product.totalStock
                              }
                            >
                              <FiPlus size={16} />
                            </button>
                          </div>
                        ) : loadingProductId === productId ? (
                          <div className="flex items-center justify-center mt-[20px]">
                            <Loader />
                          </div>
                        ) : (
                          <button
                            onClick={() => handleAddToCart(product, index)}
                            className="whitespace-nowrap mt-5 flex items-center justify-center gap-2 w-3/4 relative rounded-full border border-purple-500 text-[#3F0E9E] p-1 text-sm md:text-[12px] hover:bg-purple-100 transition disabled:opacity-50"
                            disabled={loadingProductId === productId}
                          >
                            <FaShoppingCart size={15} />
                            <span>Add to cart</span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <p className="flex justify-center w-[100%] mt-[3rem] text-red-500 font-bolder">
                {setMessage}
              </p>
            )}
          </div>
        </div>
        {allProduct.length > 0 && <Pagination />}
      </div>
      <BottomDesc
        metaInfo={metaInfoForProductListing}
        categoryDescription={categoryDescription}
      />
    </>
  );
};

export default ProductListing;
