import { DeleteRequest, GetRequest, PostRequest } from "../../api/Request";
import {
  applyCouponWhenLost,
  clearCouponSavings,
  saveAllCoupons,
  saveCart,
  setCartLength,
} from "../slice/CartSlice";
import { AppDispatch } from "../store";
import { showErrorToast, showSuccessToast } from "../../utils/toast";

export const getAllProductInCart = () => async (dispatch: AppDispatch) => {
  const token = localStorage.getItem("userToken");

  if (token) {
    // User is logged in, fetch data from the database
    try {
      const response = await GetRequest("/getCart");
      if (response.status === 200) {
        const products = response.data.items.map((item: any) => ({
          ...item.productId,
          quantityByUser: item.quantity,
          total: item.productId.salePrice * item.quantity,
        }));

        dispatch(saveCart({ products, length: response.data.items.length }));

        dispatch(setCartLength(response.data.items.length));
      } else {
        console.error("Failed to fetch cart data:", response);
        // Dispatching empty cart state in case of failure
        dispatch(saveCart({ products: [], length: 0 }));
      }
    } catch (error: any) {
      console.error("Error fetching cart data:", error.message);
      // Dispatching empty cart state in case of error
      dispatch(saveCart({ products: [], length: 0 }));
    }
  } else {
    // User is not logged in, fetch data from localStorage
    const localStorageCart = JSON.parse(localStorage.getItem("cart") || "[]");

    const products = localStorageCart.map((item: any) => ({
      ...item,
      total: item.salePrice * item.quantityByUser,
    }));

    // Dispatching to save products and cart length in Redux store
    dispatch(saveCart({ products, length: products.length }));
    dispatch(setCartLength(products.length));
  }
};

export const asyncClearCart = () => async (dispatch: AppDispatch) => {
  try {
    const response = await DeleteRequest("/clearCart");

    if (response.status === 200) {
      // If the API call is successful, clear the cart length in Redux
      dispatch(setCartLength(0));
      await dispatch(clearCouponSavings());

      return true; // Optionally, return true for further use
    } else {
      // If the API call is unsuccessful, handle the error
      throw new Error("Failed to clear cart");
    }
  } catch (error: any) {
    // If an error occurs, log and handle it
    console.error("Error clearing cart:", error.message);
    return false; // Return false or any other failure handling
  }
};

export const asyncFetchAllCoupons = () => async (dispatch: AppDispatch) => {
  try {
    const response = await GetRequest("getAllPublicCoupons");
    if (response.status === 200) {
      await dispatch(saveAllCoupons(response.data.data));
    }
  } catch (error: any) {
    showErrorToast(error.response.data.message);
  }
};

export const asyncCheckValidCoupon =
  (productIds: any, couponCode: string, discountOffer: any) =>
  async (dispatch: AppDispatch) => {
    try {
      // First API call to validate the coupon
      const response = await PostRequest("/validateCoupon", {
        productIds,
        code: couponCode,
      });

      if (response.status === 200) {
        showSuccessToast(response.data.message);
        dispatch(applyCouponWhenLost({ couponCode, discountOffer }));
      }
    } catch (error: any) {
      console.warn("Coupon validation failed, checking referral code...");

      try {
        // Second API call if the first one fails
        const referResponse = await PostRequest(
          "https://api.team.cayroshop.com/v1/user/check-valid-referralCode",
          { referralCode: couponCode }
        );

        if (referResponse.status === 200) {
          dispatch(applyCouponWhenLost({ couponCode }));
          showSuccessToast("Referral code applied successfully!");
          // You can dispatch an action here if needed
        } else {
          showErrorToast("Invalid referral code.");
        }
      } catch (referError: any) {
        showErrorToast("Invalid coupon or referral code.");
      }
    }
  };
