import Lottie from "lottie-react";
import React, { useState, useEffect } from "react";
import carticon from "../../loti/carticon.json";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import {
  allProduct,
  setCartLength,
  clearFromLocalStorage,
} from "../../store/slice/CartSlice";
import emptyCart from "../../loti/emptyCart.json";
import Loader from "../../common/loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  asyncCheckValidCoupon,
  asyncClearCart,
  asyncFetchAllCoupons,
  getAllProductInCart,
} from "../../store/actions/cartAction";
import {
  asyncRemoveItem,
  asyncUpdateQuantity,
} from "../../store/actions/productAction";

const MobileCart: React.FC = () => {
  const {
    flatTenPercentOff,
    subtotal,
    shippingCharges,
    total,
    discount,
    totalMRP,
    coupons,
    appliedCoupon,
    couponSavings,
  } = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch<AppDispatch>();
  const allProducts = useSelector(allProduct);
  const userDetail: any = useSelector((state: RootState) => state.user.user);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [percentageOff, setPercentageOff] = useState(0);
  const [shippingAddress, setShippingAddress] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [discountOffer, setDiscountOffer] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [error, setError] = useState("");
  const productIds = allProducts.map((product: any) => product._id);
  const [savedAmount, setSavedAmount] = useState(0);
  useEffect(() => {
    if (showModal) {
      dispatch(asyncFetchAllCoupons());
    }
  }, [showModal, dispatch]);

  useEffect(() => {
    let newTotalAmount = 0;
    allProducts.forEach((product: any) => {
      newTotalAmount += product?.regularPrice * (product?.quantityByUser || 0);
    });

    const newSubtotal = subtotal;
    const newDiscountAmount = newTotalAmount - newSubtotal;
    const newPercentageOff = (newDiscountAmount / newTotalAmount) * 100;

    // Update state
    setTotalAmount(() => newTotalAmount);
    setDiscountAmount(newDiscountAmount);
    setPercentageOff(newPercentageOff);
  }, [allProducts, subtotal]);

  useEffect(() => {
    dispatch(getAllProductInCart());
  }, [dispatch]);

  const handleUpdateQuantity = async (
    product: any,
    index: number,
    delta: number
  ) => {
    const newQuantity = product.quantityByUser + delta; // Increment or decrement based on current quantity
    setIsLoading(true);

    try {
      if (newQuantity <= 0) {
        await dispatch(asyncRemoveItem(product, index, 0)); // Pass 0 as quantity to trigger removal
      } else {
        await dispatch(asyncUpdateQuantity(product, index, newQuantity)); // Dispatch async action to update quantity
      }
    } catch (error) {
      console.error("Failed to update quantity:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCoupon = async () => {
    if (!coupon) {
      setError("Please enter a coupon code");
      return;
    }

    setIsLoading(true); // Start loading
    setIsClicked(true); // Mark as clicked

    try {
      await dispatch(asyncCheckValidCoupon(productIds, coupon, discountOffer));
      setIsVerified(true); // Set verified to true on success
    } catch (error) {
      setError("Invalid coupon code");
      setIsVerified(false); // Reset verified status on error
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const applyCouponFromList = (code: any, discounted: any) => {
    setCoupon(code); // Set the
    setDiscountOffer(discounted);
    setSavedAmount(discount);
  };

  const handleClearCart = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      await dispatch(asyncClearCart());
      await dispatch(clearFromLocalStorage());
      const cartItems = JSON.parse(localStorage.getItem("cart") || "[]");
      await dispatch(setCartLength(cartItems.length));
    } catch (error) {
      console.error("Error clearing cart:", error);
    } finally {
      setIsLoading(false); // Reset loading state after the action
    }
  };

  useEffect(() => {
    if (userDetail.addresses.length > 0) {
      setShippingAddress(() => {
        return userDetail.addresses[0];
      });
    }
  }, [userDetail.addresses]);

  return (
    <div
      className={
        allProducts?.length > 0
          ? "wrapper max-w-[100%] mt-[8rem] mx-auto px-4 sm:px-4 "
          : "wrapper max-w-[100%] mt-[8rem] mx-auto px-4 sm:px-4 flex justify-center items-center flex-col"
      }
    >
      {isLoading && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <Loader /> {/* Your loader component */}
        </div>
      )}
      <h1 className="text-[1.8rem] sm:text-[1.3rem] sm:ml-[-1.8rem] sm:mt-[-3rem] w-[100%] flex items-center">
        <Lottie
          animationData={carticon}
          style={{
            width: "80px",
            height: "100px",
            marginRight: "-1.4rem",
          }}
        />
        Cart
      </h1>
      <div className="widget_shopping_cart_content mt-[-2rem]">
        {allProducts.length > 0 ? (
          allProducts.map((product: any, index: number) => (
            <div
              key={product._id}
              className="woocommerce-mini-cart-item mini-cart-item flex items-center space-x-4 py-4 border-b border-gray-200"
            >
              <div className="woocommerce-mini-cart-item__thumbnail">
                <Link
                  to={`/product?name=${product.productName}&product_id=${product._id}`}
                >
                  <LazyLoadImage
                    effect="blur"
                    src={product?.images[0]?.url}
                    alt={product.productName}
                    className="w-28 h-28 md:w-24 md:h-24 object-contain rounded-lg shadow-sm object-contain"
                  />
                </Link>
              </div>
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  {/* <div className="woocommerce-mini-cart-item__name text-xs font-small text-gray-900"> */}
                  <div className="woocommerce-mini-cart-item__name text-sm font-medium text-gray-900 leading-snug">
                    <Link
                      to={`/product?name=${product.productName}&product_id=${product._id}`}
                      className="line-clamp-3"
                    >
                      {product.productName}
                    </Link>
                  </div>
                </div>
                <div className="text-xs text-gray-500">
                  <p>
                    <p className="wcfm_dashboard_item_title">
                      ₹{product.salePrice}
                    </p>
                  </p>
                </div>
                <div className="flex items-center mt-2">
                  <div className="quantity flex items-center space-x-1">
                    <button
                      className="text-gray-500 text-sm border border-gray-400 rounded-full w-6 h-6 flex justify-center items-center"
                      onClick={() => handleUpdateQuantity(product, index, -1)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-10 text-center border-gray-300 rounded-md text-sm"
                      value={product.quantityByUser}
                      readOnly
                    />
                    <button
                      onClick={() => handleUpdateQuantity(product, index, 1)}
                      className="text-gray-500 text-sm border border-gray-400 rounded-full w-6 h-6 flex justify-center items-center"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <Lottie
              animationData={emptyCart}
              loop={true}
              style={{
                width: "20rem",
                height: "20rem",
              }}
              className="w-[20rem] sm:mt-[-2rem] h-[20rem] sm:w-[12rem] sm:h-[12rem]"
            />
            <p className="text-red-400 text-lg text-center sm:text-[0.9rem]">
              No items in your cart
            </p>
          </>
        )}
      </div>
      {allProducts.length > 0 && (
        <div className="md:w-full basis-[18%] max-h-[500px] w-4/12 md:mt-4 pt-[2rem] right-bar flex-1 rounded-lg  md:mt-0 ">
          <div>
            {/* <button
              onClick={() => setShowModal(true)}
              className="w-full py-1.5 px-3 border border-primary text-primary rounded-lg hover:bg-primary hover:text-white text-sm font-semibold transition-colors shadow-sm"
            >
              Apply Coupon
            </button> */}
            <p className="flex justify-between text-sm mt-3">
              <span>Total MRP</span>
              <span>₹{totalMRP.toFixed(2)}</span>
            </p>
            <hr className="mb-3" />
            {flatTenPercentOff ? (
              <>
                <p className="flex justify-between text-sm mb-3">
                  <span className="font-medium text-normal text-green-600">
                    10% Off Orders above 1Lakh
                  </span>
                  <span className="text-green-600">
                    -₹{flatTenPercentOff.toFixed(2)}
                  </span>
                </p>
                <hr className="mb-3" />
              </>
            ) : (
              ""
            )}
            <p className="flex justify-between text-sm mb-3">
              <span className="font-medium text-normal">Total Discount</span>
              <span className="text-green-600"> - ₹{discount?.toFixed(2)}</span>
            </p>
            <hr className="mb-3" />
            <p className="flex justify-between text-sm mb-3">
              <span>Shipping Charges</span>
              <span>
                <span className="line-through">
                  ₹{shippingCharges.toFixed(2)}
                </span>
                <span className="ml-[0.5rem]"> ₹0</span>
              </span>
            </p>

            {couponSavings.code && (couponSavings.discount || 0) > 0 && (
              <p className="flex justify-between">
                <span className="font-medium text-[14px]">Coupon Discount</span>
                <span className="ml-2 text-green-600">
                  - ₹{(couponSavings.discount || 0).toFixed(2)}
                </span>
              </p>
            )}

            <div className="text-green-600 font-semibold text-sm mb-2 bg-green-100 rounded-lg p-2 text-center">
              🎉 You saved ₹{discount.toFixed(2)}!
            </div>

            <hr className="mb-3" />
            <p className="flex justify-between text-sm mb-3">
              <span>Total</span>
              <span>₹{total.toFixed(2)}</span>
            </p>
          </div>

          <div className="w-full flex  justify-end h-[100px] mt-[50px]">
            <button
              className="p-2 flex items-center bg-red-600 text-white rounded-[10px] h-[30px]"
              onClick={handleClearCart}
            >
              Clear Cart
            </button>
          </div>
          <Link
            to="/checkout"
            className="w-[80%] text-sm cursor-pointer flex items-center justify-center bg-primary text-white py-2 px-2 rounded-lg text-md w-84 m-auto relative"
          >
            Checkout
          </Link>

          <br />
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg w-[480px] max-h-[90vh] overflow-hidden shadow-2xl transition-transform transform scale-100">
            <div className="flex justify-between items-center p-4 border-b bg-gray-50">
              <h2 className="text-lg font-semibold">Apply Coupon</h2>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-500 hover:text-red-600 text-xl transition-colors"
              >
                ✕
              </button>
            </div>

            <div className="p-4 border-b">
              <div className="flex gap-2">
                <div className="relative flex-1">
                  <input
                    type="text"
                    value={coupon}
                    onChange={(e) => {
                      setCoupon(e.target.value.toUpperCase());
                      setError("");
                    }}
                    placeholder="Enter coupon code"
                    className="w-full p-3 border rounded-lg uppercase text-sm focus:border-primary focus:ring-1 focus:ring-primary transition"
                  />

                  {error && (
                    <p className="text-red-500 text-xs mt-1">{error}</p>
                  )}

                  {isVerified && (
                    <svg
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
                <button
                  onClick={handleVerifyCoupon}
                  className={`bg-primary text-white px-6 rounded-lg text-sm font-medium transition shadow-sm flex items-center justify-center ${
                    isLoading
                      ? "bg-primary/70 cursor-not-allowed"
                      : "hover:bg-primary/90"
                  }`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-4 w-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "CHECK"
                  )}
                </button>
              </div>
            </div>

            <div className="overflow-y-auto max-h-[400px]">
              <div className="p-4">
                <h3 className="text-sm font-semibold mb-4">
                  Available Coupons
                </h3>
                <div className="space-y-4">
                  {coupons?.map((coupon: any, index: number) => {
                    const formattedExpiryDate = new Date(
                      coupon.expiryDate
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    });

                    return (
                      <div
                        key={index}
                        className="border rounded-lg p-4 hover:border-primary cursor-pointer transition shadow-sm hover:shadow-md"
                        onClick={() =>
                          applyCouponFromList(coupon.code, coupon.discount)
                        }
                      >
                        <div className="flex justify-between items-start mb-2">
                          <div>
                            <p className="text-sm text-gray-600 mt-1">
                              {coupon.code}
                            </p>
                            <span className="font-semibold text-sm text-primary">
                              Rs:{coupon.discount}
                            </span>
                            <p className="text-sm text-gray-600 mt-1">
                              {coupon.name}
                            </p>
                          </div>
                          <button
                            className="text-primary text-sm font-medium hover:text-primary/80 transition-colors"
                            onClick={(e) => {
                              e.stopPropagation();
                              applyCouponFromList(coupon.code, coupon.discount);
                            }}
                          >
                            APPLY
                          </button>
                        </div>
                        <div className="flex items-center justify-between text-xs text-gray-500 mt-2">
                          <span>Expiry: {formattedExpiryDate}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileCart;
