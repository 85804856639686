import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { asyncGetDealsByDealID } from "../../../store/actions/cayroStoreAction";
import { Navigation } from "../Deals/UpperBanner";
import ProductImage from "./ProductImage";
import DealInfo from "./DealInfo";

const Product = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const searchParams = new URLSearchParams(location.search);

  // Extracting query parameters
  const { deals } = useSelector((state: RootState) => state.cayroStore);

  const totalRegularPrice = deals?.[0]?.allproducts?.reduce(
    (total: number, product: any) => {
      return total + (product?.regularPrice || 0);
    },
    0
  );

  const totalSalePrice = deals?.[0]?.allproducts?.reduce(
    (total: number, product: any) => {
      return total + (product?.salePrice || 0);
    },
    0
  );

  const dealName = searchParams.get("dealname");
  const comboName = searchParams.get("comboName");
  const dealId: any = searchParams.get("dealID");

  useEffect(() => {
    if (dealId) {
      dispatch(asyncGetDealsByDealID(dealId));
    }
  }, [dispatch, dealId]);
  const firstImages = [
    ...(deals?.[0]?.images
      ? deals[0].images.map((img: any) => img.location)
      : []), // Ensure images is an array
    ...(
      deals?.[0]?.allproducts?.map((product: any) =>
        product.images && product.images.length > 0
          ? product.images[0].url
          : null
      ) || []
    ).filter((image: any) => image !== null),
  ];

  const dealInfo = {
    products: deals?.[0]?.allproducts,
    dealName: deals?.[0]?.dealName,
    dealDiscount: deals?.[0]?.discount,
    totalPrice: totalRegularPrice,
    salePrice: totalSalePrice,
    coupon: deals?.[0]?.cupons,
    discount: deals?.[0]?.discount,
  };

  return (
    <>
      <div className="mt-[100px]">
        <Navigation comboName={comboName} dealName={dealName} />
        <div className="w-full h-full flex  md:flex-col">
          {deals && deals.length > 0 ? (
            <>
              <div className="w-[40%] md:w-full">
                <ProductImage product={firstImages} />
              </div>
              <div className="w-[55%] md:w-full">
                <DealInfo dealInfo={dealInfo} />
              </div>
            </>
          ) : (
            <p>Loading...</p> // Display a loading message while data is being fetched
          )}
        </div>
      </div>
    </>
  );
};

export default Product;
