import { NavigateFunction } from "react-router-dom";
import { saveOrders, saveUserToken, setUser, saveCompanyInfo } from "../slice/UserSlice";
import { getAllProductInCart } from "./cartAction";
import { GetRequest, PostRequest, PutRequest } from "../../api/Request";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import { AppDispatch } from "../store";
export const asyncSendOTP =
  (phoneNumber: number) =>
  async (dispatch: any): Promise<any> => {
    try {
      const response = await PostRequest("/sendLoginOTP", {
        number: phoneNumber,
      });
      if (response.status === 200) {
        await showSuccessToast("OTP Sent successfully");
      }
    } catch (error: any) {
      showErrorToast(error.response.data.message);
    }
  };

export const asyncVerifyOTP =
  (number: number, otp: number, navigate: NavigateFunction) =>
  async (dispatch: any): Promise<any> => {
    try {
      const response = await PostRequest("/loginUsingOTP", { number, otp });
      if (response.status === 200) {
        // Store token in localStorage

        await dispatch(saveUserToken(response.data.accessToken));
        localStorage.setItem("isLoggedIn", "true");
        await dispatch(getAllProductInCart());
        await showSuccessToast("Logged In Successfully");
        navigate("/");
        window.location.reload();
      }
    } catch (error: any) {
      await showSuccessToast(error.response.data.message);
    }
  };

export const asyncSendWhatsAppOTP =
  (number: number) =>
  async (dispatch: any): Promise<any> => {
    try {
      const response = await PostRequest("/send-whatsapp-otp", { number });
      if (response.status === 200) {
        await showSuccessToast("Logged In Successfully");
      }
    } catch (error: any) {
      await showErrorToast(error.response?.data?.message || "Login failed");
    }
  };

export const asyncLoginWithTruecaller =
  (reqId: any) =>
  async (dispatch: any): Promise<any> => {
    try {
      const response = await GetRequest(`/get-login-token/${reqId.reqId}`);
      if (response.status === 200) {
        await dispatch(saveUserToken(response.data.userToken));
        await showSuccessToast("Logged In Successfully");
        window.location.href = "/";
      } else {
        console.error(
          "Login failed with error message:",
          response.data?.message
        );
      }
    } catch (error: any) {
      console.error("Error occurred during login request:", error);
    }
  };

export const asyncUpdateProfile =
  (payload: any) =>
  async (dispatch: any): Promise<any> => {
    try {
      const response = await PutRequest("update-user-profile", payload);
      showSuccessToast(response.data.message);
      await dispatch(asyncFetchUserProfile());
    } catch (error: any) {
      showErrorToast(error.response.data.message);
    }
  };

export const asyncFetchUserProfile =
  () =>
  async (dispatch: AppDispatch): Promise<any> => {
    try {
      const response = await GetRequest("/user-profile");
      if (response?.data?.profile) {
        dispatch(
          setUser({
            ...response.data.profile, // Spread user profile data
            userIsLoggedIn: true,
          })
        );
      }
    } catch (err) {
      console.error(err);
      // localStorage.removeItem("userToken");
    }
  };

export const fetchAllOrders = (page: number) => {
  return async (dispatch: any) => {
    try {
      let response = await GetRequest(`getAllOrder?page=${page}`);
      if (response.status === 200) {
        await dispatch(saveOrders(response.data));
      }
    } catch (error) {
      showErrorToast("Error in fetching orders");
    }
  };
};

export const asyncCreateAddress =
  (newFormData: any) =>
  async (dispatch: AppDispatch): Promise<any> => {
    try {
      const response = await PostRequest("/create-address", newFormData);
      showSuccessToast(response.data.message);
      await dispatch(asyncFetchUserProfile());
    } catch (error: any) {
      showErrorToast(error.response.data.message);
    }
  };

export const asyncDeleteAddress =
  (indexForDelete: number) =>
  async (dispatch: AppDispatch): Promise<any> => {
    try {
      await GetRequest(`delete-address/${indexForDelete}`);
      await dispatch(asyncFetchUserProfile());
      showSuccessToast("Deleted Successfully");
    } catch (error) {
      showErrorToast("Error in Deleting the address");
    }
  };

export const asyncUpdateAddress =
  (indexForUpdate: number, updatedAddress: any) =>
  async (dispatch: AppDispatch): Promise<any> => {
    try {
      const response = await PutRequest(
        `/update-address/${indexForUpdate}`,
        updatedAddress
      );
      if (response.status === 200) {
        dispatch(asyncFetchUserProfile());
        showSuccessToast("Address updated successfully");
      }
    } catch (error) {
      showErrorToast("Failed to update address");
    }
  };


  export const asyncVerifyGSTInfo = (data: any) => async (dispatch: AppDispatch) => {
    const response = await PostRequest("verify-gst", data);
    await dispatch(saveCompanyInfo(response.data.companyName)); // Ensure this updates companyGSTInfo
    return response.data; // Optional: return data if needed
  };