import { useEffect, useState } from "react";
import { GetRequest } from "../../api/Request";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const [latestProducts, setLatestProducts] = useState<any[]>([]);
  const [trendingProducts, setTrendingProducts] = useState<any[]>([]);
  const [newProducts, setNewProducts] = useState<any[]>([]);
  const getAllLatestProducts = async () => {
    try {
      const response = await GetRequest("getAllProducts?latest=true");
      if (response.status === 200) {
        setLatestProducts(response.data.products.docs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllTrendingProducts = async () => {
    try {
      const response = await GetRequest("getAllProducts?trending=true");
      if (response.status === 200) {
        setTrendingProducts(response.data.products.docs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllNewProducts = async () => {
    try {
      const response = await GetRequest("getAllProducts?newLaunches=true");
      if (response.status === 200) {
        setNewProducts(response.data.products.docs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllLatestProducts();
    getAllTrendingProducts();
    getAllNewProducts();
  }, []);

  return (
    <div className="p-4 space-y-8 flex flex-col md:p-4">
      <h2 className="text-3xl md:text-xl font-bold  flex items-center justify-center gap-2">
        Products
        <hr className="flex-grow border-t-2 border-gray-300" />
      </h2>

      <div className="flex items-center justify-around w-full h-full sm:flex-col gap-2">
        <div className="bg-gray-100 p-4 rounded-lg shadow-md sm:w-full  w-[30%] flex flex-col overflow-scroll border-l-2 border-r-2 border-b-4 border-gray-300  shadow-gray-500/50">
          <h2 className="text-lg font-semibold mb-2 uppercase">New Launches</h2>
          <AutoCarousel products={newProducts} />
        </div>

        {/* Trending Products */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md sm:w-full w-[30%] flex flex-col overflow-scroll border-l-2 border-r-2 border-b-4 border-gray-300 shadow-gray-500/50">
          <h2 className="text-lg font-semibold mb-2 uppercase">
            Trending Products
          </h2>
          <AutoCarousel products={trendingProducts} />
        </div>

        {/* Latest Products */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-md sm:w-full  w-[30%] flex flex-col overflow-scroll border-l-2 border-r-2 border-b-4 border-gray-300 shadow-gray-500/50">
          <h2 className="text-lg font-semibold mb-2 uppercase">
            Latest Products
          </h2>
          <AutoCarousel products={latestProducts} />
        </div>
      </div>
    </div>
  );
};

const AutoCarousel = ({ products }: { products: any[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  // Divide products into chunks of 4 (2x2 grid)
  const chunkedProducts: any[][] = [];
  for (let i = 0; i < products.length; i += 4) {
    chunkedProducts.push(products.slice(i, i + 4));
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === chunkedProducts.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change slides every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [chunkedProducts.length]);

  return (
    <div className="w-full h-auto relative">
      <div className="grid grid-cols-2 gap-2">
        {chunkedProducts[currentIndex]?.map((product) => (
          <div
            key={product._id}
            onClick={() => {
              navigate(
                `/product?name=${product?.slug}&product_id=${product._id}`
              );
            }}
            className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center justify-around cursor-pointer"
          >
            <p className="text-sm font-semibold mt-2 sm:text-[10px]">
              {product.productName?.length > 20
                ? `${product.productName.slice(0, 15)}...`
                : product.productName || "No Name"}
            </p>
            <p className="text-orange-600 font-bold text-[13px]">
              ₹{product.salePrice || "N/A"}
            </p>
            <img
              src={product?.images?.[0]?.url || "/placeholder-image.jpg"}
              alt={product.productName || "Product Image"}
              className="w-full h-[100px] object-contain"
            />
          </div>
        ))}
      </div>

      {/* Dots Navigation */}
      <div className="absolute bottom-[-10px] left-1/2 transform -translate-x-1/2 flex space-x-2">
        {chunkedProducts.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-1 h-1 rounded-full cursor-pointer ${
              currentIndex === index ? "bg-blue-500" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Products;
