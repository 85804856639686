import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define specific types for your store (replace `any` with more specific types if possible)
interface ComboDeal {
  id: string;
  title: string;
  discount: number;
  // other properties depending on your structure
}

interface Combo {
  id: string;
  items: string[];
  // other properties depending on your structure
}

interface CayroStoreState {
  cayroStoreBanner: string | null; // Assuming it's a string or null, change as needed
  comboDeals: ComboDeal[] | null;
  metaInformation: any;
  deals: any | null; // Consider adding a more specific type for deals
  combos: Combo[];
}

const initialState: CayroStoreState = {
  cayroStoreBanner: null,
  comboDeals: null,
  combos: [],
  metaInformation: [],
  deals: null,
};

// Create the slice with proper types
const cayroStoreSlice = createSlice({
  name: "cayrostore",
  initialState,
  reducers: {
    saveCombos: (state, action: PayloadAction<Combo[]>) => {
      state.combos = action.payload;
    },
    saveCayroStoreBanners: (state, action: PayloadAction<string | null>) => {
      state.cayroStoreBanner = action.payload;
    },
    saveComboDeals: (state, action: PayloadAction<ComboDeal[]>) => {
      state.comboDeals = action.payload;
    },
    saveDeals: (state, action: PayloadAction<any>) => {
      state.deals = action.payload; // Again, consider using a more specific type for deals
    },
    saveMetaInformation: (state, action: PayloadAction<any[]>) => {
      state.metaInformation = action.payload; // Save array of meta information
    },
  },
});

// Export the reducer to be used in the store
export default cayroStoreSlice.reducer;

// Export actions
export const {
  saveCombos,
  saveCayroStoreBanners,
  saveComboDeals,
  saveDeals,
  saveMetaInformation,
} = cayroStoreSlice.actions;
