import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetRequest } from "../../api/Request";

type Image = {
  publicId: string;
  url: string;
  _id: string;
};

type Blog = {
  createdAt: string | number | Date;
  id: number;
  images: Image[];
  title: string;
  description: string;
  date: string;
  metaDescription: string;
  metaKeyword: string;
  metaTitle: string;
};

interface BlogsState {
  blogs: Blog[];
  loading: boolean;
  error: string | null;
  selectedBlog: Blog | null; // New state to store the selected blog data
}

// Initial state
const initialState: BlogsState = {
  blogs: [],
  loading: false,
  error: null,
  selectedBlog: null, // Initialize selectedBlog as null
};

export const fetchBlogs = createAsyncThunk(
  "blogs/fetchBlogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await GetRequest("/getAllBlog");
      return response.data.data.docs;
    } catch (error: any) {
      console.error("Error fetching blogs:", error);
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

// Fetch a single blog by its ID
export const asyncFetchBlogById = (id: any) => async (dispatch: any) => {
  try {
    const response = await GetRequest(`/getById/${id}`);
    await dispatch(setSelectedBlog(response.data.data)); // Dispatch the action to set the selected blog in the state
  } catch (error) {
    console.error("Error fetching blog details:", error);
  }
};

// Reducer to store the selected blog
const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setSelectedBlog: (state, action) => {
      state.selectedBlog = action.payload; // Update the selected blog state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setSelectedBlog } = blogsSlice.actions; // Export the action to set the selected blog

export default blogsSlice.reducer;
