import React, { useState } from "react";
import { FaLocationArrow } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { applyCoupon } from "../../../store/slice/CartSlice";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../common/loader/Loader";
import { BsCart3 } from "react-icons/bs";
import { showSuccessToast } from "../../../utils/toast";
import { AppDispatch } from "../../../store/store";
import { asyncAddToCart } from "../../../store/actions/productAction";

interface ProductInfoProps {
  dealInfo: any;
}

const DealInfo: React.FC<ProductInfoProps> = ({ dealInfo }) => {
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [reviewData, setReviewData] = useState({
    avgRating: 0,
  });

  const [isInCart, setIsInCart] = useState(false); // State to track if product is added to cart

  const handleAddToCartClick = (product: any, coupon: any) => {
    handleAddToCart(product, coupon);
    setIsInCart(true); // Set the product as added to cart
  };

  const handleBuyNowClick = async (product: any, coupon: any) => {
    await handleAddToCart(product, coupon);
    await navigate("/cart"); // This will only execute after products are added

    setIsInCart(true);
  };

  const handleAddToCart = async (products: any[], coupon: any) => {
    setIsAddedToCart(true); // Show loader by setting loading state to true
    let flag = false;
    try {
      // Loop through each product and dispatch the action
      for (const product of products) {
        await dispatch(asyncAddToCart(product, flag)); // Call the action function for each product
      }
      const payload = {
        coupon: coupon,
        products: products,
      };
      await dispatch(applyCoupon(payload));
      showSuccessToast("Products Added to cart");
    } catch (error) {
      console.error("Error adding to cart:", error);
    } finally {
      setIsAddedToCart(false);
    }
  };
  const isAnyProductOutOfStock = dealInfo?.products?.some(
    (product: any) => product.totalStock === 0
  );

  return (
    <>
      <div className="p-6 sm:p-3 bg-white ">
        {isAddedToCart && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
            <Loader /> {/* Your loader component */}
          </div>
        )}

        <h1 className="text-2xl  mb-2 sm:text-sm">{dealInfo?.dealName} </h1>
        <div className="flex items-center justify-between">
          <Link
            to="/contact"
            className="block text-blue-500 mb-2 text-center flex items-center hover:underline text-xs"
          >
            <FaLocationArrow className="mr-2" />
            Visit Cayro Shop store
          </Link>
        </div>
        <StarRatings rating={reviewData.avgRating} />

        <hr className="border-t-2 border-gray-300 my-4 w-[60%] sm:w-full" />

        <div className="flex flex-col justify-between items-start relative space-x-4 sm:flex lg:flex-row">
          {/* Left Side - Product Information */}
          <div className="flex flex-col justify-center p-[10px] w-full sm:w-[50%]">
            {/* Discount, Price, and Regular Price */}
            <div className="mb-4 flex items-center space-x-4">
              <h2 className="text-2xl flex justify-center font-semibold sm:text-sm">
                ₹{dealInfo?.salePrice}
              </h2>
              <h2 className="text-md line-through text-red-500 sm:text-sm">
                ₹{dealInfo?.totalPrice}
              </h2>
            </div>

            {/* Stock Availability */}
            <div className="flex items-center gap-8 ">
              <p
                className={
                  isAnyProductOutOfStock
                    ? "text-yellow-500 font-semibold text-sm sm:text-xs"
                    : "text-[#009c89] font-semibold text-sm sm:text-xs"
                }
              >
                {isAnyProductOutOfStock ? "Out of Stock" : "In Stock"}
              </p>
            </div>

            {/* Additional Info (Shipping and EMI) */}
            <div className="flex flex-col gap-[6px] mt-[10px]">
              <h1 className="text-yellow-400 font-bold">Our Services</h1>
              <p className="text-[14px]">Free Shipping on all Orders</p>
              <p className="text-[14px]">No Cost EMI available</p>
            </div>
          </div>

          {/* Right Side - Offers */}
          {dealInfo?.totalStock === 0 ? null : (
            <>
              <div className="offer-card block xl:hidden absolute right-[-4%] top-[-32%] sm:relative sm:top-auto sm:right-auto">
                <div className="bg-white shadow-md p-2 w-72 border border-gray-300">
                  <h3 className="text-lg font-semibold mb-4 flex items-center gap-3">
                    <img
                      src="/offers/offer-icon.svg"
                      className="w-[40px] h-[40px]"
                      alt=""
                    />
                    Available Offers
                  </h3>

                  <div className="space-y-2">
                    {/* Main Card for Bank Offer */}
                    <div className="bg-white rounded-lg shadow-sm p-3 border border-gray-200">
                      <div className="flex items-start gap-3">
                        <div>
                          <p className="text-sm font-medium">Bank Offer</p>
                          <p className="text-xs text-gray-600">
                            Upto 5% instant discount on HDFC Bank Credit Cards
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Main Card for Special Price */}
                    <div className="bg-white rounded-lg shadow-sm p-3 border border-gray-200">
                      <div className="flex items-start gap-3">
                        <div>
                          <p className="text-sm font-medium">Special Price</p>
                          <p className="text-xs text-gray-600">
                            Get extra 5% off (price inclusive of discount)
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Main Card for No Cost EMI */}
                    <div className="bg-white rounded-lg shadow-sm p-3 border border-gray-200">
                      <div className="flex items-start gap-3">
                        <div>
                          <p className="text-sm font-medium">No Cost EMI</p>
                          <p className="text-xs text-gray-600">
                            Avail No Cost EMI on select cards for orders above
                            ₹3000
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <hr className="border-t-2 border-gray-300 my-4 w-[60%] sm:w-full" />

        <div className="sm:flex-col flex flex-col items-start gap-[10px]">
          <div className="flex space-x-4">
            {isAnyProductOutOfStock ? (
              <button
                className=" text-gray-500 bg-gray-200 cursor-not-allowed px-4 py-2 rounded flex-1 "
                disabled
              >
                Out of Stock
              </button>
            ) : (
              <>
                <button
                  onClick={() =>
                    handleAddToCartClick(dealInfo.products, dealInfo.coupon)
                  }
                  className="border sm:min-w-[120px] sm:text-xs border-blue-500 text-blue-500 sm:px-2 sm:py-1 px-4 py-2 rounded flex-1 min-w-[184px] hover:bg-white hover:text-blue-500"
                >
                  Add to cart
                  <BsCart3 className="inline mb-[0.2rem] ml-[0.2rem]" />
                  {dealInfo?.isLoading && (
                    <div className="absolute top-[-18px] left-[14px]">
                      <Loader />
                    </div>
                  )}
                </button>

                <button
                  type="submit"
                  onClick={async () => {
                    await handleBuyNowClick(dealInfo.products, dealInfo.coupon);
                  }}
                  className="border sm:min-w-[120px] transition-transform duration-300 transform sm:text-xs text-white bg-blue-500 px-4 py-2 rounded flex-1 min-w-[184px] hover:scale-105"
                >
                  Buy Now
                </button>
              </>
            )}
          </div>
        </div>

        <hr className="border-t-2 border-gray-300 my-4 w-[60%] sm:w-full" />

        {dealInfo?.salePrice ? (
          <div className="mt-4">
            <ul className="flex justify-evenly items-end gap-2 ">
              {/* Free Shipping */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/fast_delivery.svg"
                  className="w-[30px] xss:w-[35px] xs:w-[40px] sm:w-[25px] lg:w-[30px]"
                  alt="Fast Delivery"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[11px] lg:text-[13px] text-cyan-400">
                  Free shipping
                </h1>
              </li>

              {/* No Cost EMI */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/Group.svg"
                  className="w-[25px] xss:w-[30px] xs:w-[35px] sm:w-[40px] lg:w-[25px]"
                  alt="No Cost EMI"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[13px] lg:text-[14px] text-cyan-400">
                  No Cost EMI
                </h1>
              </li>

              {/* Top Brand */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/top_brand.svg"
                  className="w-[30px] xss:w-[35px] xs:w-[40px] sm:w-[25px] lg:w-[30px]"
                  alt="Top Brand"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[13px] lg:text-[14px] text-cyan-400">
                  Top Brand
                </h1>
              </li>

              {/* Cayro Delivery */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/cayro_delivery.svg"
                  className="w-[30px] xss:w-[35px] xs:w-[40px] sm:w-[25px] lg:w-[30px]"
                  alt="Cayro Delivery"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[13px] lg:text-[14px] text-cyan-400">
                  Cayro Delivery
                </h1>
              </li>

              {/* Secure Transaction */}
              <li className="flex flex-col items-center text-center">
                <img
                  src="/productInfo/secure_transaction.svg"
                  className="w-[30px] xss:w-[35px] xs:w-[40px] sm:w-[25px] lg:w-[30px]"
                  alt="Secure Transaction"
                />
                <h1 className="mt-2 text-[12px] xss:text-[11px] xs:text-[12px] sm:text-[13px] lg:text-[14px] text-cyan-400">
                  Secure Transaction
                </h1>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
        <hr className="border-t-2 border-gray-300 my-4 w-[105%] sm:w-full" />
      </div>
    </>
  );
};

const StarRatings = ({ rating }: { rating: number }) => {
  const fullStars = Math.floor(5); // Number of full stars
  const halfStar = rating % 1 >= 0.5; // Whether to show a half star
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining empty stars

  return (
    <div className="flex gap-[5px]">
      {/* Full stars */}
      {Array.from({ length: fullStars }).map((_, index) => (
        <span key={`full-${index}`} className="text-yellow-500">
          ★
        </span>
      ))}

      {/* Half star */}
      {halfStar && <span className="text-yellow-500">☆</span>}

      {/* Empty stars */}
      {Array.from({ length: emptyStars }).map((_, index) => (
        <span key={`empty-${index}`} className="text-gray-300">
          ★
        </span>
      ))}
    </div>
  );
};

export default DealInfo;
