import { useMediaQuery } from "@react-hook/media-query";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { RxCrossCircled } from "react-icons/rx";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductImage = React.memo((props: any) => {
  let { product } = props;
  const [mainImage, setMainImage] = useState<string | undefined>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const lensRef = useRef<HTMLDivElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const zoomedImgRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isZoomed, setIsZoomed] = useState(false);
  const productUrl = window.location.href;

  const handleDoubleClick = () => {
    setIsZoomed(true);
  };

  const closeZoom = () => {
    setIsZoomed(false);
  };

  useEffect(() => {
    if (product?.length > 0) {
      setMainImage(product[0]);
      setCurrentIndex(0); // Initialize currentIndex
    }
  }, [product]);

  useEffect(() => {
    if (product?.length > 0) {
      setMainImage(product[currentIndex]);
    }
  }, [currentIndex, product]);

  const handleSwipe = (direction: string) => {
    if (product?.length > 0) {
      if (direction === "left") {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? product.length - 1 : prevIndex - 1
        );
      } else {
        setCurrentIndex((prevIndex) =>
          prevIndex === product.length - 1 ? 0 : prevIndex + 1
        );
      }
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const lens = lensRef.current;
    const img = imgRef.current;
    const zoomedImg = zoomedImgRef.current;

    if (lens && img && zoomedImg) {
      const { width, height, left, top } = img.getBoundingClientRect();
      const x = e.clientX - left;
      const y = e.clientY - top;

      let lensX = x - lens.offsetWidth / 2;
      let lensY = y - lens.offsetHeight / 2;

      lensX = Math.max(0, Math.min(lensX, width - lens.offsetWidth));
      lensY = Math.max(0, Math.min(lensY, height - lens.offsetHeight));

      lens.style.display = "block";
      zoomedImg.style.display = "block";

      lens.style.left = `${lensX}px`;
      lens.style.top = `${lensY}px`;

      const backgroundX = (x / width) * 100;
      const backgroundY = (y / height) * 100;

      zoomedImg.style.backgroundPosition = `${backgroundX}% ${backgroundY}%`;
    }
  };

  const handleMouseOut = () => {
    const lens = lensRef.current;
    const zoomedImg = zoomedImgRef.current;

    if (lens) lens.style.display = "none";
    if (zoomedImg) zoomedImg.style.display = "none";
  };

  const [showShareBox, setShowShareBox] = useState(false);

  const shareText = "Check out this amazing product!";

  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
    shareText + " " + productUrl
  )}`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    shareText + " " + productUrl
  )}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    productUrl
  )}`;

  return (
    <div className="product-imgs basis-[35%] sm:basis-[100%] mt-[2rem] sm:mt-[1.8rem] relative p-5">
      <Helmet>
        <meta property="og:image" content={mainImage} />
      </Helmet>

      <div className="flex items-center ">
        {showShareBox && (
          <div className="absolute top-[50px] left-[10px] z-[50] bg-white border border-gray-300 rounded-lg shadow-lg p-4 w-[220px]">
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-red-500"
              onClick={() => setShowShareBox(false)}
            >
              ✖
            </button>
            <div className="flex justify-evenly ">
              <a
                href={whatsappUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-start space-x-2 p-2 bg-green-500 text-white rounded-md hover:bg-green-600"
              >
                <FaWhatsapp className="text-white text-xl" />
              </a>
              <a
                href={twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-start space-x-2 p-2 bg-blue-400 text-white rounded-md hover:bg-blue-500"
              >
                <FaXTwitter className="text-white text-xl" />
              </a>
              <a
                href={facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-start space-x-2 p-2 bg-blue-800 text-white rounded-md hover:bg-blue-900"
              >
                <FaFacebook className="text-white text-xl" />
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row-reverse md:flex-col gap-3">
        <div className="overflow-hidden flex justify-center items-center gap-4">
          <div
            className="img-showcase relative flex transition-all duration-500 flex-col"
            onMouseMove={!isMobile ? handleMouseMove : () => {}}
            onMouseOut={!isMobile ? handleMouseOut : () => {}}
            onTouchStart={(e) => {
              const touchStartX = e.touches[0].clientX;
              const handleTouchEnd = (e: any) => {
                const touchEndX = e.changedTouches[0].clientX;
                if (touchStartX > touchEndX + 50) {
                  handleSwipe("right");
                } else if (touchStartX < touchEndX - 50) {
                  handleSwipe("left");
                }
                document.removeEventListener("touchend", handleTouchEnd);
              };
              document.addEventListener("touchend", handleTouchEnd);
            }}
          >
            <p ref={imgRef}>
              {" "}
              <LazyLoadImage
                effect="blur"
                src={mainImage}
                onDoubleClick={isMobile ? handleDoubleClick : () => {}}
                alt={product?.productName}
                className="h-[350px] mb-[1rem] w-[480px] sm:h-[170px] sm:w-[100%] object-contain sm:mb-[0.5rem] duration-300"
              />
            </p>

            {isZoomed && (
              <div className="fixed z-[999999] inset-0  bg-[#ffffffe6]  flex justify-center items-center">
                <div className="relative p-4">
                  <LazyLoadImage
                    effect="blur"
                    src={mainImage}
                    alt={product?.productName}
                    onTouchStart={(e) => {
                      const touchStartX = e.touches[0].clientX;
                      const handleTouchEnd = (e: any) => {
                        const touchEndX = e.changedTouches[0].clientX;
                        if (touchStartX > touchEndX + 50) {
                          handleSwipe("right");
                        } else if (touchStartX < touchEndX - 50) {
                          handleSwipe("left");
                        }
                        document.removeEventListener(
                          "touchend",
                          handleTouchEnd
                        );
                      };
                      document.addEventListener("touchend", handleTouchEnd);
                    }}
                    className="max-w-full max-h-full"
                  />
                  <button
                    onClick={closeZoom}
                    className="absolute top-2 right-2 text-red text-2xl  z-[9999]"
                  >
                    <RxCrossCircled className="text-red-500 font-700 text-[19px] z-[9999]" />
                  </button>
                </div>
              </div>
            )}
            <div
              ref={lensRef}
              className="absolute w-[50px] h-[50px] bg-black bg-opacity-40 border-2 border-gray-500 rounded-full pointer-events-none"
              style={{ display: "none" }}
            ></div>
          </div>
        </div>

        {/* Zoomed Image Display */}

        <div
          ref={zoomedImgRef}
          className="absolute top-0 left-[110%] z-[9999] w-[750px] h-[550px] bg-cover bg-no-repeat border border-gray-300"
          style={{
            backgroundImage: `url(${mainImage})`,
            backgroundSize: "200%",
            display: "none", // Initially hidden
          }}
        ></div>

        <div className="h-[400px] md:h-[75px] flex flex-col md:flex-row overflow-x-scroll sm:overflow-y-scroll mt-[20px] relative">
          {product?.length > 0 &&
            product.map((image: any, index: number) => {
              return (
                <div
                  key={index}
                  className="m-1 cursor-pointer flex-shrink-0"
                  onMouseEnter={() => setMainImage(image)}
                >
                  <LazyLoadImage
                    effect="blur"
                    src={image}
                    className="w-[75px] h-[55px] object-contain border p-[5px] border-gray-500 mt-[10px]"
                    alt="product image"
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
});

export default ProductImage;
