import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { asyncGetDealsByComboName } from "../../../store/actions/cayroStoreAction";
import { useLocation } from "react-router-dom";
import UpperBanner from "./UpperBanner";
import MainSection from "./MainSection";
import Products from "./Products";
import { Helmet } from "react-helmet";

const ComboDeals = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const comboName: string | null = searchParams.get("comboName");
  const id: string | null = searchParams.get("id");

  const { comboDeals, metaInformation } = useSelector(
    (state: RootState) => state.cayroStore
  );

  useEffect(() => {
    if (id) {
      dispatch(asyncGetDealsByComboName(id));
    }
  }, [dispatch, id]);

  // Access the first metaInformation object if it's available

  return (
    <>
      <Helmet>
        <meta name="title" content={metaInformation.metaInfoTitle} />
        <meta
          name="description"
          content={metaInformation.metaInfoDescription}
        />
        <meta
          name="keywords"
          content={metaInformation?.metaInfoKeyword?.join(", ")}
        />
      </Helmet>
      <div className="h-full bg-gray-50 mt-[100px]">
        <UpperBanner comboName={comboName} />
        <MainSection heading={comboName} />
        <Products combodeals={comboDeals} />
      </div>
    </>
  );
};

export default ComboDeals;
