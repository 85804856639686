import { useMediaQuery } from "@react-hook/media-query";
import { useEffect, useState } from "react";
import { FaShippingFast } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Banners = () => {
  const isMobile = useMediaQuery("(max-width: 520px)");
  const navigate = useNavigate();
  const products = [
    {
      name: "Television",
      description: "Haier 32 inch 80cm Bezel Smart TV",
      price: "₹12800",
      image:
        "https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741080753772_1741080753758.webp",
      url: "/product?name=haier-32-inch-80cm-bezel-less-smart-led-hd-ready-tv-le32a7-n-&product_id=67b854c2386a47f57d4e6d0a",
    },
    {
      name: "Small Personal Care",
      description: "Morphy Richards Brut Xtra 800W Mixer Grinder",
      price: "₹3498",
      image:
        "https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741080811596_1741080811593.webp",
      url: "/product?name=morphy-richards-brut-xtra-800w-mixer-grinder-black-gold-&product_id=67a1edca17312666809d30ac",
    },
    {
      name: "Laptop",
      description: "HP 15-FC0030AU AMD Ryzen 5",
      price: "₹40499",
      image:
        "https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741080249836_1741080249835.webp",
      url: "/product?name=hp-15-fc0030au-amd-ryzen-5-15-6-inch-16gb-512gb-windows-11-ms-office-2021-amd-radeon-fhd-ips-display-natural-silver-7l034pa-&product_id=6788a7150c24ffbf45fd1a22",
    },
    {
      name: "Washing Machine",
      description: "Lg, 9Kg 5 Star",
      price: "₹38999",
      image:
        "https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/images/1741080210352_1741080210346.webp",
      url: "/product?name=Lg-9Kg-5-Star-Front-Load-Washing-Machine-AI-Direct-Drive-Platinum-Silver-FHP1209Z7P&product_id=66c9b5b7ad0fde84ea1a78e9",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % products.length);
    }, 3000); // Slide changes every 3 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [products.length]);

  return (
    <>
      <div className="flex  w-full p-4 md:flex-col md:h-auto gap-4 ">
        {!isMobile ? (
          // Desktop View
          <div className="left w-[80%] flex flex-col gap-4 md:w-full md:gap-4 grid grid-cols-2 lg:gap-4">
            {products.slice(0, 4).map((product, index) => (
              <div
                key={index}
                className="cursor-pointer gap-4 flex w-full h-full bg-[rgb(226,229,231)] p-4 justify-center items-center lg:flex-col"
                onClick={() => navigate(product?.url)}
              >
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-[200px] h-[200px] lg:w-[150px] lg:h-[150px]"
                />
                <div className="flex flex-col text-left w-full lg:text-center">
                  <h4>{product.name}</h4>
                  <p className="font-semibold">{product.description}</p>
                  <h4 className="underline">{product.price}</h4>
                </div>
              </div>
            ))}
          </div>
        ) : (
          // Mobile Autocarousel View
          <div className="w-full flex flex-col items-center">
            <div className="relative w-full overflow-hidden">
              <div
                className="flex transition-transform duration-500"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
              >
                {products?.map((product, index) => (
                  <div
                    key={index}
                    className="min-w-full flex flex-col items-center justify-center bg-[rgb(226,229,231)] p-4"
                    onClick={() => navigate(product.url)}
                  >
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-[150px] h-[150px] object-fit"
                    />
                    <div className="flex flex-col text-center w-full">
                      <h4>{product.name}</h4>
                      <p className="font-semibold">{product.description}</p>
                      <p className="underline">{product.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="right w-[20%] flex flex-col items-center justify-evenly border md:w-full md:hidden">
          <h5 className="text-xl font-bold mb-4">Our Services</h5>
          <hr className="w-full border-t-2 border-gray-300 mb-4" />

          {/* Free Shipping */}
          <div className="flex flex-col items-center text-center mb-6 md:mb-4">
            <FaShippingFast className="text-3xl text-blue-500 mb-2" />
            <p className="text-lg font-semibold">Free Shipping</p>
            <p className="text-gray-600 text-sm">Free Shipping On All Orders</p>
          </div>

          {/* 24/7 Support */}
          <div className="flex flex-col items-center text-center mb-6 md:mb-4">
            <MdSupportAgent className="text-3xl text-blue-500 mb-2" />
            <p className="text-lg font-semibold">24/7 Support</p>
            <p className="text-gray-600 text-sm">24/7 Support On All Orders</p>
          </div>

          {/* Secure Payment */}
          <div className="flex flex-col items-center text-center">
            <RiSecurePaymentFill className="text-3xl text-blue-500 mb-2" />
            <p className="text-lg font-semibold">Secure Payment</p>
            <p className="text-gray-600 text-sm">
              Secure Payment On All Orders
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banners;
