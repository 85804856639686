const BottomDesc = ({ metaInfo, categoryDescription }: any) => {
  const hasMetaInfo = metaInfo?.metaInfoTitle && metaInfo?.metaInfoDescription;
  return (
    hasMetaInfo && (
      <div className="bg-gray-100 p-6 ">
        {/* Meta title section */}
        <h2 className="text-lg font-semibold text-gray-800 mb-2">
          {metaInfo?.metaInfoTitle}
        </h2>
        {categoryDescription &&
        categoryDescription?.length != 16 &&
        categoryDescription.length != 9 ? (
          <p
            className="text-sm text-black text-[20px]"
            dangerouslySetInnerHTML={{ __html: categoryDescription }}
          />
        ) : (
          <>
            <p className="text-sm text-black text-[20px]">
              {metaInfo?.metaInfoDescription}
            </p>
          </>
        )}
      </div>
    )
  );
};

export default BottomDesc;
