import { useEffect } from "react";
import { Link } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetChildCategories } from "../../store/actions/productAction";

const StyleSection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { childCategory } = useSelector((state: RootState) => state.product);

  useEffect(() => {
    dispatch(asyncGetChildCategories());
  }, []);

  return (
    <div className="">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-blue-50 to-blue-100">
        <div className="w-full mx-auto px-4 py-8">
          <div className="text-center max-w-4xl mx-auto sm:text-left">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">
              CayroShop: Your Go-To Online Electronics Store in Delhi NCR
            </h1>
            <h3 className="text-gray-600 text-sm md:text-base leading-relaxed">
              cayroshop.com is the Best electronic shopping hub in Gurugram,
              where a wide variety of affordable electronics are available, such
              as smart LED TVs, energy-efficient washing machines, and more.
            </h3>
          </div>
        </div>
      </div>

      {/* Categories Section */}
      <div className="w-full shadow-sm mt-4">
        <div className="p-6">
          <h2 className="text-[20px]  font-medium  mb-4 pl-4">Top Stories</h2>
          <div className="flex w-full flex-wrap">
            {childCategory?.map((item: any, idx) => (
              <div
                key={idx}
                className="hover:bg-blue-50 rounded-lg transition-colors duration-200"
              >
                <Link
                  to={`category/${item.routeSlug}`}
                  className="block p-3 text-sm text-gray-600 hover:text-blue-600"
                >
                  <div className="text-left">{item.name}</div>
                </Link>
              </div>
            ))}
          </div>
        </div>

        {/* Additional Info */}
        <div className="p-6  text-center border-t">
          <h2 className="text-sm mb-4 font-semibold sm:text-left">
            Best online electronics store in Delhi NCR, bestowing India with
            budget-friendly electronics and fast and reliable delivery of
            electronics in Gurugram.
          </h2>
          {/* <button className="bg-blue-600 hover:bg-blue-700 text-white font-medium px-6 py-2 rounded-lg text-sm transition-colors duration-200">
            Shop Now!
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default StyleSection;
