import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Define the initial state
export const initialState: any = {
  compareTheseProducts: [],
  subCategoryId: null,
};

// Create the slice
export const productComparisonSlice = createSlice({
  name: "productComparison",
  initialState,
  reducers: {
    addProductForComparison: (state, action: PayloadAction<any>) => {
      state.compareTheseProducts.push(action.payload);
      state.subCategoryId = action?.payload?.subCategoryId;
    },
    removeProductForComparison: (
      state,
      action: PayloadAction<{ _id: string }>
    ) => {
      state.compareTheseProducts = state.compareTheseProducts.filter(
        (product: any) => product._id !== action.payload
      );
    },
    setSubCategoryIdForComparison: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.subCategoryId = action.payload;
    },
    clearComparison: (state) => {
      state.compareTheseProducts = [];
      state.subCategoryId = "";
    },
  },
});

// Export actions
export const {
  addProductForComparison,
  removeProductForComparison,
  setSubCategoryIdForComparison,
  clearComparison,
} = productComparisonSlice.actions;

// Export the reducer and initial state
export default productComparisonSlice.reducer;

export const compareProducts = (state: RootState) => {
  return state.productComparison.compareTheseProducts;
};
export const subCategoryIdForComparison = (state: RootState) => {
  return state.productComparison.subCategoryId;
};
