import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { totalPageForProduct } from "../store/slice/ProductSlice";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Pagination = () => {
  const totalPages = useSelector(totalPageForProduct);
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const [pageForProduct, setPageForProduct] = useState<number>(
    parseInt(query.get("page") || "1", 10)
  );

  useEffect(() => {
    const pageParam = query.get("page");
    if (pageParam) {
      setPageForProduct(parseInt(pageParam, 10));
    }
  }, [location.search]);

  const giveUrlAccToPage = (index: number) => {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);
    searchParams.set("page", index.toString());
    return `${currentUrl.pathname}?${searchParams.toString()}`;
  };

  // Function to generate page numbers with ellipses
  const getPaginationNumbers = () => {
    const visiblePages = 3; // Number of pages to show around the current page
    let pages: (number | string)[] = [];

    if (totalPages <= 5) {
      // Show all pages if totalPages is 5 or less
      pages = Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      // Always show first page
      pages.push(1);

      if (pageForProduct > visiblePages + 1) {
        pages.push("...");
      }

      let start = Math.max(2, pageForProduct - 1);
      let end = Math.min(totalPages - 1, pageForProduct + 1);

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (pageForProduct < totalPages - visiblePages) {
        pages.push("...");
      }

      // Always show last page
      pages.push(totalPages);
    }

    return pages;
  };

  return (
    <div className="flex justify-center mt-6 ">
      <nav aria-label="Pagination">
      <ul className="flex flex-nowrap justify-center gap-2 w-full overflow-x-auto">
      {/* First Page */}
          <li>
            <Link
              to={giveUrlAccToPage(1)}
              className={`flex items-center justify-center w-[30px] h-[30px] border rounded-md ${
                pageForProduct === 1 ? "text-gray-300 cursor-not-allowed" : "hover:bg-gray-100"
              }`}
            >
              <FiChevronsLeft size={18} />
            </Link>
          </li>

          {/* Previous Page */}
          <li>
            <Link
              to={giveUrlAccToPage(Math.max(1, pageForProduct - 1))}
              className={`flex items-center justify-center w-[30px] h-[30px] border rounded-md ${
                pageForProduct === 1 ? "text-gray-300 cursor-not-allowed" : "hover:bg-gray-100"
              }`}
            >
              <BsChevronLeft size={18} />
            </Link>
          </li>

          {/* Page Numbers with Ellipses */}
          {getPaginationNumbers().map((page, index) => (
            <li key={index}>
              {typeof page === "number" ? (
                <Link
                  to={giveUrlAccToPage(page)}
                  className={`flex items-center justify-center w-[30px] h-[30px] border rounded-md text-sm font-medium ${
                    pageForProduct === page
                      ? "bg-purple-600 text-white border-gray-300"
                      : "text-gray-500 hover:bg-gray-100"
                  }`}
                >
                  {page}
                </Link>
              ) : (
                <span className="flex items-center justify-center w-[30px] h-[30px] text-gray-500">...</span>
              )}
            </li>
          ))}

          {/* Next Page */}
          <li>
            <Link
              to={giveUrlAccToPage(Math.min(totalPages, pageForProduct + 1))}
              className={`flex items-center justify-center w-[30px] h-[30px] border rounded-md ${
                pageForProduct === totalPages ? "text-gray-300 cursor-not-allowed" : "hover:bg-gray-100"
              }`}
            >
              <BsChevronRight size={18} />
            </Link>
          </li>

          {/* Last Page */}
          <li>
            <Link
              to={giveUrlAccToPage(totalPages)}
              className={`flex items-center justify-center w-[30px] h-[30px] border rounded-md ${
                pageForProduct === totalPages ? "text-gray-300 cursor-not-allowed" : "hover:bg-gray-100"
              }`}
            >
              <FiChevronsRight size={18} />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
