import { Link } from "react-router-dom";
import { Reviews } from "./Reviews";

const ProductDescription = ({ product }: { product: any }) => {
  return (
    <>
      <div className="border border-purple-500 p-4 rounded-lg">
        <h2 className="text-[20px] font-semibold mb-2 sm:text-sm text-red-900">
          Product Description
        </h2>

        <div
          className="order text-gray-800 p-3 sm:text-[11px]"
          dangerouslySetInnerHTML={{
            __html: product?.description,
          }}
        />
      </div>

      {/* Apply explicit Tailwind styles */}

      {product?.specification?.[1]?.feature?.length > 0 && (
        <div className="mt-5">
          <h2 className="text-[20px] font-semibold mb-2 sm:text-sm text-red-900">
            {product?.specification?.[1]?.heading}
          </h2>

          <div className="border border-purple-500 p-4 rounded-lg overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="border-b border-purple-500 text-green-800">
                  <th className="px-4 py-2 text-left text-[14px] sm:text-[11px]">
                    Feature
                  </th>
                  <th className="px-4 py-2 text-left text-[14px] sm:text-[11px]">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                {product?.specification?.[1]?.feature
                  ?.filter(
                    (item: any) =>
                      item?.label && item?.value && item?.value !== "-"
                  ) // Exclude empty values and dashes
                  .map((item: any, index: number) => (
                    <tr key={index} className="border-b border-purple-500">
                      <td className="px-4 py-2 font-medium text-[14px] sm:text-[11px]">
                        {item.label}
                      </td>
                      <td className="px-4 py-2 text-[14px] sm:text-[11px]">
                        {item.value}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="w-full bg-yellow-100 mt-[50px] flex justify-between items-center px-6 py-4 shadow-md rounded-lg smm:flex-col sm:gap-4 sm:text-center">
        {/* Left Section */}
        <div className="flex items-center gap-4 smm:flex-col sm:gap-2">
          <img
            src="referral_image.webp"
            alt="Refer a Friend"
            className="h-16 w-auto sm:h-12"
          />
          <h1 className="text-lg font-bold text-gray-900 sm:text-base">
            Refer Friends & Earn
          </h1>
        </div>

        {/* Right Section (Button) */}
        <Link
          to="http://team.cayroshop.com/"
          className="border border-red-600 text-red-600 px-4 py-2 rounded-md font-semibold hover:bg-red-600 hover:text-white transition sm:px-3 sm:py-1 sm:text-sm"
        >
          Refer Bonus
        </Link>
      </div>

      <Reviews product={product} />

      <div className="mt-5 w-full bg-white border border-gray-300 rounded-lg flex justify-between items-center px-6 py-4 shadow-sm sm:flex-col sm:items-start sm:text-center sm:gap-3">
        {/* Left Content */}
        <div className="sm:w-full">
          <h1 className="text-lg font-bold text-red-800 sm:text-base">
            Frequently Asked Questions
          </h1>
          <p className="text-gray-600 capitalize sm:text-sm">
            Have questions about this product? Ask us!
          </p>
        </div>

        {/* Right Button */}
        <Link to="/faqs">
          <button className="border border-green-600 text-green-600 px-3 py-2 rounded-md font-semibold hover:bg-green-600 hover:text-white transition sm:w-full sm:text-sm">
            ASK NOW
          </button>
        </Link>
      </div>
    </>
  );
};

export default ProductDescription;
