import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface SearchState {
  searchProducts: any[];
  filters: any;
  selectedFilter: any[];
  totalPages: any;
  page: any;
  totalItems: number;
  limitForProduct: number;
  isLoading: boolean;
  isLoadingFilters: boolean;
  metaInfo: any;
  searchText: string;
}

// Define the initial state
const initialState: SearchState = {
  searchProducts: [],
  filters: {},
  selectedFilter: [],
  totalPages: 0,
  page: 1,
  totalItems: 0,
  limitForProduct: 12,
  isLoading: false,
  isLoadingFilters: false,
  metaInfo: {},
  searchText: "",
};

// Async thunk for fetching products

// Async thunk for fetching filters

// Define the search slice
const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchProducts: (state, action: PayloadAction<any>) => {
      state.searchProducts = action.payload;
    },
    setFilters(state, action: PayloadAction<any>) {
      state.filters = action.payload;
    },
    setSelectedFilter(state, action: PayloadAction<any[]>) {
      state.selectedFilter = action.payload;
    },
    setLoadingFilters(state, action: PayloadAction<boolean>) {
      state.isLoadingFilters = action.payload;
    },
    setPage(
      state,
      action: PayloadAction<{ totalPages: number; currentPage: number }>
    ) {
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.currentPage;
    },

    setLoadingProducts(state, action: PayloadAction<any>) {
      state.isLoading = action.payload;
    },
  },
});

// Export actions and reducer
export const {
  setFilters,
  setSelectedFilter,
  setPage,
  setSearchProducts,
  setLoadingFilters,
  setLoadingProducts,
} = searchSlice.actions;
export const selectSearch = (state: RootState) => state.search;
export default searchSlice.reducer;
