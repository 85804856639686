import { useState, useEffect } from "react";

const HomeAppliances = () => {
  const images = [
    {
      src: "https://cayrobucket.blr1.digitaloceanspaces.com/images/1740205576340_1740205576339.webp",
      link: "/category/home-appliances/air-conditioner",
    },
    {
      src: "https://cayrobucket.blr1.digitaloceanspaces.com/images/1740205399253_1740205399246.webp",
      link: "/category/home-appliances/air-purifiers",
    },
    {
      src: "https://cayrobucket.blr1.digitaloceanspaces.com/images/1740205549218_1740205549216.webp",
      link: "/category/home-appliances/room-heater",
    },
    {
      src: "https://cayrobucket.blr1.digitaloceanspaces.com/images/1740205471967_1740205471966.webp",
      link: "/category/home-appliances/vacuum-cleaner",
    },
  ];

  const brands = [
    {
      name: "Air Cooler",
      logo: "https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/product/67da9061d71a38adf2c1db07/1742377494283_1742377494248.webp",
      link: "/category/air-coolers",
    },
    {
      name: "Air Conditioner",
      logo: "https://mycayroshopbucket.s3.ap-south-1.amazonaws.com/cayroshop/product/67a603903a4e121a2d71387f/1739016447905_1739016447887.webp",
      link: "/category/home-appliances/air-conditioner",
    },
    {
      name: "Air Purifier",
      logo: "https://cayrobucket.blr1.digitaloceanspaces.com/product/67162504f730a37683d9acf2/1729572131691_1729572131646.webp",
      link: "/category/home-appliances/air-purifiers",
    },
    {
      name: "Refrigerator",
      logo: "https://cayrobucket.blr1.digitaloceanspaces.com/product/672893e79bcfd5d639de188b/1731406775064_1731406775040.webp",
      link: "/category/home-appliances/refrigerators--freezers",
    },
    {
      name: "Toaster",
      logo: "https://cayrobucket.blr1.digitaloceanspaces.com/product/670f66aefb24225aa2de802b/1729160663382_1729160662747.webp",
      link: "/category/kitchen-appliances/toasters--sandwich-makers/toaster",
    },
    {
      name: "Microwave Oven",
      logo: "https://cayrobucket.blr1.digitaloceanspaces.com/product/66c9b5b7ad0fde84ea1a78ec/1730107225678_1730107225655.webp",
      link: "/category/kitchen-appliances/microwave-ovens",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [images.length]);

  return (
    <div className="flex p-4 w-full flex-col">
      <h3 className=" mb-4 text-[20px] font-semibold w-full text-left">
        Home Appliances
      </h3>
      <div className="flex flex-row sm:flex-col sm:w-full">
        {/* Carousel Section */}
        <div className="w-full md:w-full flex justify-center items-center relative ">
          <a
            href={images[currentIndex].link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images[currentIndex].src}
              alt={`Appliance ${currentIndex + 1}`}
              className="w-full h-auto object-contain rounded-lg"
            />
          </a>
          {/* Navigation Dots */}
          <div className="absolute bottom-1 flex space-x-2">
            {images.map((_, index) => (
              <span
                key={index}
                className={`cursor-pointer transition-all duration-300 ${
                  currentIndex === index
                    ? "w-6 h-2 bg-blue-500 rounded-full"
                    : "w-2 h-2 bg-gray-300 rounded-full"
                }`}
                onClick={() => setCurrentIndex(index)}
              ></span>
            ))}
          </div>
        </div>

        {/* Brands Section */}
        <div className="w-full md:w-full mt-4 md:mt-0 md:ml-4 p-4">
          <div className="grid grid-cols-3 gap-4">
            {brands.map((brand, index) => (
              <a
                key={index}
                href={brand.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-center items-center text-center space-y-2 flex-col"
              >
                <img
                  src={brand.logo}
                  alt={brand.name}
                  className="w-16 h-16 object-contain"
                />
                <span className="text-sm font-medium">{brand.name}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAppliances;
